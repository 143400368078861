import React, { useState } from "react"
import PropTypes from "prop-types"

import SearchTab from "./searchTab/SearchTab"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Container variant="optionsDiv">
					<Typography component={"div"}>{children}</Typography>
				</Container>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	}
}

const Options = (props) => {
	const [selectedObjectFilter, setSelectedObjectFilter] = useState("")
	const [selectedMemoryFilter, setSelectedMemoryFilter] = useState("")
	const [selectedPeriodFilter, setSelectedPeriodFilter] = useState("")

	return (
		<Container variant="optionsDiv">
			<SearchTab
				initialObjectsList={props.initialObjectsList}
				setSelectedObject={props.setSelectedObject}
				selectedObject={props.selectedObject}
				selectedObjectFilter={selectedObjectFilter}
				setSelectedObjectFilter={setSelectedObjectFilter}
				selectedMemoryFilter={selectedMemoryFilter}
				setSelectedMemoryFilter={setSelectedMemoryFilter}
				selectedPeriodFilter={selectedPeriodFilter}
				setSelectedPeriodFilter={setSelectedPeriodFilter}
        setVisible={props.setVisible}
			/>
		</Container>
	)
}

export default Options
