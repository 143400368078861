import React from "react"
import { useTranslation } from "react-i18next"

import { langFieldsDict } from "../../../utils/personsArcgisItems"

import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const PersonHeader = (props) => {
	const { t, i18n } = useTranslation()

	const getLangName = (nameLT, nameEN, nameRU, namePL, mainLanguage, nameOther) => {
		let name

		if (i18n.language === "lt") {
			name = nameLT
		} else {
			if (i18n.language === "en" && nameEN) {
				name = nameEN
			} else if (i18n.language === "ru" && nameRU) {
				name = nameRU
			} else if (i18n.language === "pl" && namePL) {
				name = namePL
			} else {
				switch (mainLanguage) {
					case "LT":
						name = nameLT
						break
					case "EN":
						name = nameEN
						break
					case "RU":
						name = nameRU
						break
					case "PL":
						name = namePL
						break
					default:
						name = nameOther
				}
			}
		}

		return name
	}

	return (
		props.biographyFeatures.length > 0 && (
			<Box sx={{ ml: 2, mt: 1, mb: 1 }}>
				<Typography
					sx={{ fontWeight: "bold", color: "black" }}
					variant="h4"
					gutterBottom={false}
					component="div"
					align="left"
				>
					{getLangName(
						props.biographyFeatures[0].attributes.Vardas_lietuviskai,
						props.biographyFeatures[0].attributes.Vardas_pavarde_EN,
						props.biographyFeatures[0].attributes.Vardas_pavarde_RU,
						props.biographyFeatures[0].attributes.Vardas_pavarde_PL,
						props.biographyFeatures[0].attributes.Kuri_kalba_pagrindine,
						props.biographyFeatures[0].attributes.Vardas_pavarde_KITA
					)}
				</Typography>

				{getLangName(
					props.biographyFeatures[0].attributes.Vardas_lietuviskai,
					props.biographyFeatures[0].attributes.Vardas_pavarde_EN,
					props.biographyFeatures[0].attributes.Vardas_pavarde_RU,
					props.biographyFeatures[0].attributes.Vardas_pavarde_PL,
					props.biographyFeatures[0].attributes.Kuri_kalba_pagrindine,
					props.biographyFeatures[0].attributes.Vardas_pavarde_KITA
				) !== props.biographyFeatures[0].attributes.Vardas_lietuviskai && (
					<Typography sx={{ color: "black", fontSize: "1.875rem" }} variant="h4" component="div" align="left">
						{props.biographyFeatures[0].attributes.Vardas_lietuviskai}
					</Typography>
				)}

				{getLangName(
					props.biographyFeatures[0].attributes.Vardas_lietuviskai,
					props.biographyFeatures[0].attributes.Vardas_pavarde_EN,
					props.biographyFeatures[0].attributes.Vardas_pavarde_RU,
					props.biographyFeatures[0].attributes.Vardas_pavarde_PL,
					props.biographyFeatures[0].attributes.Kuri_kalba_pagrindine,
					props.biographyFeatures[0].attributes.Vardas_pavarde_KITA
				) !== props.biographyFeatures[0].attributes.Vardas_pavarde_EN && (
					<Typography sx={{ color: "black", fontSize: "1.875rem" }} variant="h4" component="div" align="left">
						{props.biographyFeatures[0].attributes.Vardas_pavarde_EN}
					</Typography>
				)}

				{getLangName(
					props.biographyFeatures[0].attributes.Vardas_lietuviskai,
					props.biographyFeatures[0].attributes.Vardas_pavarde_EN,
					props.biographyFeatures[0].attributes.Vardas_pavarde_RU,
					props.biographyFeatures[0].attributes.Vardas_pavarde_PL,
					props.biographyFeatures[0].attributes.Kuri_kalba_pagrindine,
					props.biographyFeatures[0].attributes.Vardas_pavarde_KITA
				) !== props.biographyFeatures[0].attributes.Vardas_pavarde_RU && (
					<Typography sx={{ color: "black", fontSize: "1.875rem" }} variant="h4" component="div" align="left">
						{props.biographyFeatures[0].attributes.Vardas_pavarde_RU}
					</Typography>
				)}

				{getLangName(
					props.biographyFeatures[0].attributes.Vardas_lietuviskai,
					props.biographyFeatures[0].attributes.Vardas_pavarde_EN,
					props.biographyFeatures[0].attributes.Vardas_pavarde_RU,
					props.biographyFeatures[0].attributes.Vardas_pavarde_PL,
					props.biographyFeatures[0].attributes.Kuri_kalba_pagrindine,
					props.biographyFeatures[0].attributes.Vardas_pavarde_KITA
				) !== props.biographyFeatures[0].attributes.Vardas_pavarde_PL && (
					<Typography sx={{ color: "black", fontSize: "1.875rem" }} variant="h4" component="div" align="left">
						{props.biographyFeatures[0].attributes.Vardas_pavarde_PL}
					</Typography>
				)}

				{getLangName(
					props.biographyFeatures[0].attributes.Vardas_lietuviskai,
					props.biographyFeatures[0].attributes.Vardas_pavarde_EN,
					props.biographyFeatures[0].attributes.Vardas_pavarde_RU,
					props.biographyFeatures[0].attributes.Vardas_pavarde_PL,
					props.biographyFeatures[0].attributes.Kuri_kalba_pagrindine,
					props.biographyFeatures[0].attributes.Vardas_pavarde_KITA
				) !== props.biographyFeatures[0].attributes.Vardas_pavarde_KITA && (
					<Typography sx={{ color: "black", fontSize: "1.875rem" }} variant="h4" component="div" align="left">
						{props.biographyFeatures[0].attributes.Vardas_pavarde_KITA}
					</Typography>
				)}

				{props.biographyFeatures[0].attributes.Pseudonimas_ir_slapyvardziai && (
					<Typography
						variant="h5"
						gutterBottom={
							props.biographyFeatures[0].attributes.Vardas_pavarde_EN ||
							props.biographyFeatures[0].attributes.Vardas_pavarde_KITA ||
							props.biographyFeatures[0].attributes.Vardas_pavarde_PL ||
							props.biographyFeatures[0].attributes.Vardas_pavarde_RU
								? false
								: true
						}
						component="div"
						align="left"
						color="text.secondary"
					>
						{t("fields.nickname")} - {props.biographyFeatures[0].attributes.Pseudonimas_ir_slapyvardziai}
					</Typography>
				)}

				{props.biographyFeatures[0].attributes[langFieldsDict[i18n.language].Veikla_kuryba_trumpai] && (
					<Typography color="text.secondary" variant="body2" gutterBottom component="div" align="left">
						{props.biographyFeatures[0].attributes[langFieldsDict[i18n.language].Veikla_kuryba_trumpai]}
					</Typography>
				)}
			</Box>
		)
	)
}

export default PersonHeader
