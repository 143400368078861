import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { map, map2, view, view2, objects, maps } from "../../../utils/periodsArcgisItems"
import DNRSpinner from "../../../utils/misc/DNRSpinner"

import Backdrop from "@mui/material/Backdrop"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"

import TileLayer from "@arcgis/core/layers/TileLayer"
import MapImageLayer from "@arcgis/core/layers/MapImageLayer"

const periods = [
	{ title: "1808" },
	{ title: "1845" },
	{ title: "1911" },
	{ title: "1938" },
	{ title: "1977" },
	{ title: "2023" },
	{ title: "2024" },
]

const CompareWindow = (props) => {
	const { t, i18n } = useTranslation()

	const [selectedLeftPeriod, setSelectedLeftPeriod] = useState(props.selectedPeriods[0])
	const [selectedRightPeriod, setSelectedRightPeriod] = useState(props.selectedPeriods[1])
	const [layerLoading, setLayerLoading] = useState(true)

	const isMobile = useMediaQuery("(min-width:600px)")

	const handleLeftSelect = (event) => {
		setSelectedLeftPeriod(event.target.value)
		props.setSelectedPeriods([event.target.value, props.selectedPeriods[1]])
	}

	const handleRightSelect = (event) => {
		setSelectedRightPeriod(event.target.value)
		props.setSelectedPeriods([props.selectedPeriods[0], event.target.value])
	}

	useEffect(() => {
		setLayerLoading(true)

		view2.zoom = view.zoom
		view2.center = view.center

		map.removeAll()
		map2.removeAll()

		maps
			.queryFeatures({
				where: `Grupe = 'Istorinių topografijų rekonstrukcijos'`,
				outFields: ["Nuoroda", "Sudarytas"],
				returnGeometry: false,
			})
			.then((response) => {
				const swipeWidgetFind = view.ui.find("swipe-layers")
				if (swipeWidgetFind !== null) {
					view.ui.remove(swipeWidgetFind)
					swipeWidgetFind.destroy()
				}

				let subLayerLeft
				let urlNewLeft
				const urlSplitLeft = response.features[selectedLeftPeriod].attributes.Nuoroda.split("/")
				subLayerLeft = parseInt(urlSplitLeft.slice(-1))
				urlNewLeft = urlSplitLeft.slice(0, -1).join("/")

				const leftPeriod = new MapImageLayer({
					url: urlNewLeft,
					title: `${response.features[selectedLeftPeriod].attributes.Nuoroda}`,
					metai: response.features[selectedLeftPeriod].attributes.Sudarytas,
					sublayers: [{ id: subLayerLeft }],
				})

				let subLayerRight
				let urlNewRight
				const urlSplitRight = response.features[selectedRightPeriod].attributes.Nuoroda.split("/")
				subLayerRight = parseInt(urlSplitRight.slice(-1))
				urlNewRight = urlSplitRight.slice(0, -1).join("/")

				const rightPeriod = new MapImageLayer({
					url: urlNewRight,
					title: `${response.features[selectedRightPeriod].attributes.Nuoroda}`,
					metai: response.features[selectedRightPeriod].attributes.Sudarytas,
					sublayers: [{ id: subLayerRight }],
				})

        let subLayerFirst
				let urlNewFirst
				const urlSplitFirst = response.features[0].attributes.Nuoroda.split("/")
				subLayerFirst = parseInt(urlSplitFirst.slice(-1))
				urlNewFirst = urlSplitFirst.slice(0, -1).join("/")

				const firstPeriod = new MapImageLayer({
					url: urlNewFirst,
					title: `${response.features[0].attributes.Nuoroda}`,
					metai: response.features[0].attributes.Sudarytas,
					sublayers: [{ id: subLayerFirst }],
				})

				let subLayerLast
				let urlNewLast
				const urlSplitLast = response.features[6].attributes.Nuoroda.split("/")
				subLayerLast = parseInt(urlSplitLast.slice(-1))
				urlNewLast = urlSplitLast.slice(0, -1).join("/")

				const lastPeriod = new MapImageLayer({
					url: urlNewLast,
					title: `${response.features[6].attributes.Nuoroda}`,
					metai: response.features[6].attributes.Sudarytas,
					sublayers: [{ id: subLayerLast }],
				})

				map.add(leftPeriod)
				map2.add(rightPeriod)

				if (selectedLeftPeriod === selectedRightPeriod && selectedLeftPeriod === 6) {
					setSelectedRightPeriod(0)
					props.setSelectedPeriods([selectedLeftPeriod, 0])

					map.removeAll()
					map2.removeAll()

					map.add(leftPeriod)
					map2.add(firstPeriod)
				} else if (selectedLeftPeriod === selectedRightPeriod) {
					setSelectedRightPeriod(6)
					props.setSelectedPeriods([selectedLeftPeriod, 6])

					map.removeAll()
					map2.removeAll()

					map.add(leftPeriod)
					map2.add(lastPeriod)
				}

				setLayerLoading(false)
				props.setToggleCompareWindow(true)
			})
	}, [selectedLeftPeriod, selectedRightPeriod])

	useEffect(() => {
		return () => {
			props.setToggleCompareWindow(false)

			map.removeAll()
			map2.removeAll()
			map.add(objects)

			objects
				.when(() => {
					return objects.queryExtent()
				})
				.then((response) => {
					view.constraints.geometry = {
						type: "extent",
						spatialReference: response.extent.spatialReference,
						xmin: response.extent.xmin,
						ymin: response.extent.ymin,
						xmax: response.extent.xmax,
						ymax: response.extent.ymax,
					}
					view2.constraints.geometry = {
						type: "extent",
						spatialReference: response.extent.spatialReference,
						xmin: response.extent.xmin,
						ymin: response.extent.ymin,
						xmax: response.extent.xmax,
						ymax: response.extent.ymax,
					}
				})
		}
	}, [])

	return (
		<Grid
			sx={{
				backgroundColor: "yellow",
				width: "100%",
				height: "0%",
				position: "relative",
			}}
			container
			direction="row"
			justifyContent="center"
			alignItems="flex-start"
		>
			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={layerLoading}>
				<DNRSpinner />
			</Backdrop>
			<FormControl
				sx={{
					bottom: 16,
					mt: -7.5,
					mr: isMobile ? 8 : 18,
					width: "auto",
					height: "45px",
					backgroundColor: "white",
					boxShadow: 0,
				}}
				variant="outlined"
				size="small"
				id="swipe-select"
			>
				<Select
					value={selectedLeftPeriod}
					onChange={handleLeftSelect}
					renderValue={(value) => (
						<Typography sx={{ color: "#D72E30" }} component={"div"}>
							<Typography sx={{ color: "black", display: "inline" }}>{t("sys.leftMap")}: </Typography>
							{periods[value].title}
						</Typography>
					)}
					MenuProps={{
						anchorOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
					}}
				>
					{periods.map(
						(object, index) =>
							index !== selectedRightPeriod && (
								<MenuItem
									sx={{
										whiteSpace: "unset",
										"&.Mui-selected": {
											color: "#D72E30",
											backgroundColor: "#F7D5D6",
										},
										justifyContent: "center",
									}}
									key={index}
									value={index}
								>
									{object.title}
								</MenuItem>
							)
					)}
				</Select>
			</FormControl>
			<FormControl
				sx={{
					bottom: 16,
					mt: -7.5,
					ml: isMobile ? 8 : 19.5,
					width: "auto",
					height: "45px",
					backgroundColor: "white",
					boxShadow: 0,
				}}
				variant="outlined"
				size="small"
				id="swipe-select"
			>
				<Select
					value={selectedRightPeriod}
					onChange={handleRightSelect}
					renderValue={(value) => (
						<Typography sx={{ color: "#D72E30" }} component={"div"}>
							<Typography sx={{ color: "black", display: "inline" }}>{t("sys.rightMap")}: </Typography>
							{periods[value].title}
						</Typography>
					)}
					MenuProps={{
						anchorOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
					}}
				>
					{periods.map(
						(object, index) =>
							index !== selectedLeftPeriod && (
								<MenuItem
									sx={{
										whiteSpace: "unset",
										"&.Mui-selected": {
											color: "#D72E30",
											backgroundColor: "#F7D5D6",
										},
										justifyContent: "center",
									}}
									key={index}
									value={index}
								>
									{object.title}
								</MenuItem>
							)
					)}
				</Select>
			</FormControl>
		</Grid>
	)
}

export default CompareWindow
