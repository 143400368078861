import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { view, objects, gatves_istorines, periods, map } from "../../../utils/streetsArcgisItems"

import { styled } from "@mui/material/styles"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import ShareIcon from "@mui/icons-material/Share"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import Box from "@mui/material/Box"
import Pagination from "@mui/material/Pagination"
import CircularProgress from "@mui/material/CircularProgress"
import useMediaQuery from "@mui/material/useMediaQuery"
import Backdrop from "@mui/material/Backdrop"
import Fade from "@mui/material/Fade"

let highlight
const ObjectPopupTimeline = (props) => {
	const { globalID } = useParams()
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()

	const [loading, setLoading] = useState(true)
	const [queryObjects, setQueryObjects] = useState([])
	const [popupOpen, setPopupOpen] = useState(false)
	const [page, setPage] = useState(1)
	const [pageCount, setPageCount] = useState(1)
	const [shareTooltip, setShareTooltip] = useState(false)
	const [relatedStreets, setRelatedStreets] = useState([])
	const [relatedMaps, setRelatedMaps] = useState([])

	const handlePage = (event, value) => {
		navigate(
			`/vilniausdnr/${i18n.language}/streets/compare/timeline/${queryObjects[
				value - 1
			].attributes.GlobalID.replace(/[{}]/g, "")}`
		)
	}

	const BootstrapTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.secondary.main,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.secondary.main,
			fontSize: 15,
		},
	}))

	const handleShare = async () => {
		await navigator.clipboard.writeText(window.location.href)
		setShareTooltip(true)
	}

	useEffect(() => {
		if (props.mapQuery.length === 0 || !props.mapQuery.length) {
			setPopupOpen(true)
			setLoading(true)

			gatves_istorines
				.queryFeatures({
					where: `GlobalID = '${globalID}'`,
					outFields: ["*"],
					returnGeometry: true,
				})
				.then((response) => {
					if (response.features.length > 0) {
						setQueryObjects(response.features)
						setLoading(false)

						if (highlight) {
							highlight.remove()
						}

						map.removeAll()
						props.setToggle1808(false)
						props.setToggle1845(false)
						props.setToggle1911(false)
						props.setToggle1938(false)
						props.setToggle1977(false)
						props.setToggle2023(false)

						switch (response.features[0].attributes.Metai) {
							case "1808":
								props.setToggle1808(true)
								map.add(periods[0])

								view.whenLayerView(periods[0]).then((periodView) => {
									view.goTo(response.features[0].geometry.extent)
									highlight = periodView.highlight(response.features[0])
								})
								break

							case "1845":
								props.setToggle1845(true)
								map.add(periods[1])

								view.whenLayerView(periods[1]).then((periodView) => {
									view.goTo(response.features[0].geometry.extent)
									highlight = periodView.highlight(response.features[0])
								})
								break

							case "1911":
								props.setToggle1911(true)
								map.add(periods[2])

								view.whenLayerView(periods[2]).then((periodView) => {
									view.goTo(response.features[0].geometry.extent)
									highlight = periodView.highlight(response.features[0])
								})
								break

							case "1938":
								props.setToggle1938(true)
								map.add(periods[3])

								view.whenLayerView(periods[3]).then((periodView) => {
									view.goTo(response.features[0].geometry.extent)
									highlight = periodView.highlight(response.features[0])
								})
								break

							case "1977":
								props.setToggle1977(true)
								map.add(periods[4])

								view.whenLayerView(periods[4]).then((periodView) => {
									view.goTo(response.features[0].geometry.extent)
									highlight = periodView.highlight(response.features[0])
								})
								break

							case "2021":
								props.setToggle2023(true)
								map.add(periods[5])

								view.whenLayerView(periods[5]).then((periodView) => {
									view.goTo(response.features[0].geometry.extent)
									highlight = periodView.highlight(response.features[0])
								})
								break
						}

						gatves_istorines
							.queryRelatedFeatures({
								outFields: ["*"],
								relationshipId: 4,
								returnGeometry: false,
								objectIds: response.features[0].attributes.OBJECTID,
							})
							.then((response_related) => {
								setRelatedStreets(response_related[response.features[0].attributes.OBJECTID].features)
							})

						gatves_istorines
							.queryRelatedFeatures({
								outFields: ["*"],
								relationshipId: 8,
								returnGeometry: false,
								objectIds: response.features[0].attributes.OBJECTID,
							})
							.then((response_related) => {
								setRelatedMaps(response_related[response.features[0].attributes.OBJECTID].features)
							})
					}
				})
		}
	}, [])

	useEffect(() => {
		// if (!props.initialLoading) {
		if (props.mapQuery.length > 0) {
			setPopupOpen(true)
			setLoading(true)
			setRelatedStreets([])
			setRelatedMaps([])

			let found = false
			let tempPage = 0
			for (let obj in props.mapQuery) {
				if (props.mapQuery[obj].attributes.GlobalID.replace(/[{}]/g, "") === globalID) {
					setPage(parseInt(obj) + 1)
					found = true
					tempPage = parseInt(obj)

					switch (props.mapQuery[obj].attributes.Metai) {
						case "1808":
							props.setToggle1808(true)
							map.add(periods[0])

							view.whenLayerView(periods[0]).then((periodView) => {
								view.goTo(props.mapQuery[obj].geometry.extent)
								highlight = periodView.highlight(props.mapQuery[obj])
							})
							break

						case "1845":
							props.setToggle1845(true)
							map.add(periods[1])

							view.whenLayerView(periods[1]).then((periodView) => {
								view.goTo(props.mapQuery[obj].geometry.extent)
								highlight = periodView.highlight(props.mapQuery[obj])
							})
							break

						case "1911":
							props.setToggle1911(true)
							map.add(periods[2])

							view.whenLayerView(periods[2]).then((periodView) => {
								view.goTo(props.mapQuery[obj].geometry.extent)
								highlight = periodView.highlight(props.mapQuery[obj])
							})
							break

						case "1938":
							props.setToggle1938(true)
							map.add(periods[3])

							view.whenLayerView(periods[3]).then((periodView) => {
								view.goTo(props.mapQuery[obj].geometry.extent)
								highlight = periodView.highlight(props.mapQuery[obj])
							})
							break

						case "1977":
							props.setToggle1977(true)
							map.add(periods[4])

							view.whenLayerView(periods[4]).then((periodView) => {
								view.goTo(props.mapQuery[obj].geometry.extent)
								highlight = periodView.highlight(props.mapQuery[obj])
							})
							break

						case "2021":
							props.setToggle2023(true)
							map.add(periods[5])

							view.whenLayerView(periods[5]).then((periodView) => {
								view.goTo(props.mapQuery[obj].geometry.extent)
								highlight = periodView.highlight(props.mapQuery[obj])
							})
							break
					}
				}
			}

			if (found) {
				setQueryObjects(props.mapQuery)
				setPageCount(props.mapQuery.length)
			} else {
				setPageCount(1)
				setPage(1)
			}

			if (highlight) {
				highlight.remove()
			}

			gatves_istorines
				.queryRelatedFeatures({
					outFields: ["*"],
					relationshipId: 6,
					returnGeometry: false,
					objectIds: props.mapQuery[tempPage].attributes.OBJECTID,
				})
				.then((response) => {
					setRelatedStreets(response[props.mapQuery[tempPage].attributes.OBJECTID].features)
				})

			gatves_istorines
				.queryRelatedFeatures({
					outFields: ["*"],
					relationshipId: 10,
					returnGeometry: false,
					objectIds: props.mapQuery[tempPage].attributes.OBJECTID,
				})
				.then((response) => {
					setRelatedMaps(response[props.mapQuery[tempPage].attributes.OBJECTID].features)
				})

			setLoading(false)
		} else {
		}
		// }
	}, [
		globalID,
		// props.initialLoading
	])

	// useEffect(() => {
	// 	if (popupOpen) {
	// 		navigate(`/vilniausdnr/${i18n.language}/streets/compare/timeline`)
	// 	}
	// }, [
	// 	props.toggle1808,
	// 	props.toggle1845,
	// 	props.toggle1911,
	// 	props.toggle1938,
	// 	props.toggle1977,
	// 	props.toggle2023,
	// ])

	useEffect(() => {
		return () => {
			setPage(1)
			setPageCount(1)
			props.setSelectedObject("")
			setQueryObjects([])
			setPopupOpen(false)

			if (highlight) {
				highlight.remove()
			}
		}
	}, [])

	const matches = useMediaQuery("(min-width:600px)")
	return (
		<>
			{!matches && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 2 }}
					open={popupOpen}
					onClick={() => setPopupOpen(false)}
				></Backdrop>
			)}
			<Fade in={true} timeout={300} unmountOnExit>
				<Box sx={{ top: 90, right: 0, position: "fixed", zIndex: 10, mt: 0.5 }}>
					<Card variant="popup">
						<CardContent sx={{ pt: 0, px: 4 }}>
							{pageCount > 1 ? (
								<Box component="div" display="flex" justifyContent="center" alignItems="center">
									<Pagination
										sx={{ mb: 1, ".MuiPaginationItem-root": { color: "white" } }}
										color="secondary"
										count={pageCount}
										page={page}
										onChange={handlePage}
									/>
								</Box>
							) : null}
							{loading ? (
								<Box display="flex" justifyContent="center" alignItems="center">
									<CircularProgress />
								</Box>
							) : (
								<>
									<CardHeader
										sx={{ p: 0, mt: 1 }}
										action={
											<IconButton
												color="primary"
												aria-label="close"
												size="small"
												onClick={() => {
													navigate(`/vilniausdnr/${i18n.language}/streets/compare/timeline`)
												}}
												sx={{
													mt: 1,
													mr: -1.5,
													backgroundColor: "#F6F6F6",
													"&:hover": {
														transition: "0.3s",
														backgroundColor: "white",
													},
												}}
											>
												<CloseIcon sx={{ fontSize: 25 }} />
											</IconButton>
										}
										title={
											<>
												<Typography
													sx={{ color: "white", fontWeight: 600, fontSize: "26px", display: "inline" }}
												>
													{queryObjects[page - 1].attributes.Pavadinimas}
													<BootstrapTooltip
														open={shareTooltip}
														leaveDelay={1000}
														title={t(`sys.shareUrl`)}
														arrow
														placement="top"
														onClose={() => {
															setShareTooltip(false)
														}}
													>
														<IconButton
															color="secondary"
															aria-label="share"
															size="medium"
															onClick={handleShare}
															sx={{ mt: -0.5 }}
														>
															<ShareIcon style={{ fontSize: 25 }} />
														</IconButton>
													</BootstrapTooltip>
												</Typography>
											</>
										}
									/>

									{queryObjects[page - 1].attributes.Metai && (
										<Typography
											sx={{ color: "white", fontWeight: 500, fontSize: "14px" }}
											variant="body2"
											component="div"
										>
											{queryObjects[page - 1].attributes.Metai}
										</Typography>
									)}

									{relatedStreets.length ? (
										<>
											<Typography
												sx={{ color: "white", fontWeight: 500, fontSize: "18px" }}
												variant="h6"
												component="div"
											>
												{t("fields.currentStreet")}
											</Typography>
											<Typography component="div">
												{Object.keys(relatedStreets).map((street) => (
													<>
														<div key={street}>
															<Link
																sx={{ mt: 0.5 }}
																textAlign="left"
																component="button"
																variant="body2"
																onClick={() => {
																	props.setHistoryToggle(false)
																	map.removeAll()
																	map.add(objects)
																	navigate(
																		`/vilniausdnr/${i18n.language}/streets/object/${relatedStreets[street].attributes.GAT_ID}`
																	)
																}}
															>{`${relatedStreets[street].attributes.PAV}`}</Link>
															<br></br>
														</div>
													</>
												))}
											</Typography>
										</>
									) : null}

									{relatedMaps.length ? (
										<>
											<Typography
												sx={{ color: "white", fontWeight: 500, fontSize: "18px" }}
												variant="h6"
												component="div"
											>
												{t("fields.mapsRelated")}
											</Typography>
											<Typography component="div">
												{Object.keys(relatedMaps).map((map) => (
													<div key={map}>
														<Link
															sx={{ mt: 0.5 }}
															target="_blank"
															href={
																"https://zemelapiai.vplanas.lt" +
																`/vilniausdnr/${i18n.language}/maps/compare/review/${relatedMaps[
																	map
																].attributes.GlobalID_zemelapio.replace(/[{}]/g, "")}?x=${view.center.x}&y=${
																	view.center.y
																}&zoom=${view.zoom + 12}`
															}
															rel="noopener"
															textAlign="left"
															variant="body2"
														>
															{i18n.language === "lt"
																? relatedMaps[map].attributes.Pavadinimas
																: i18n.language === "en"
																? relatedMaps[map].attributes.Pavadinimas_ANG
																: i18n.language === "ru"
																? relatedMaps[map].attributes.Pavadinimas_RUS
																: relatedMaps[map].attributes.Pavadinimas_LEN}
														</Link>
														<br></br>
													</div>
												))}
											</Typography>
										</>
									) : null}
								</>
							)}
						</CardContent>
					</Card>
				</Box>
			</Fade>
		</>
	)
}

export default ObjectPopupTimeline
