import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { map, view, objects, maps } from "../../../utils/periodsArcgisItems"
import DNRSpinner from "../../../utils/misc/DNRSpinner"

import Backdrop from "@mui/material/Backdrop"
import ButtonGroup from "@mui/material/ButtonGroup"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Slider from "@mui/material/Slider"
import Box from "@mui/material/Box"
import useMediaQuery from "@mui/material/useMediaQuery"

import TileLayer from "@arcgis/core/layers/TileLayer"
import MapImageLayer from "@arcgis/core/layers/MapImageLayer"

const CompareTimeline = (props) => {
	const { globalID } = useParams()
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()

	const [sliderValue, setSliderValue] = useState(100)
	const [layerLoading, setLayerLoading] = useState(true)

	const isMobile = useMediaQuery("(min-width:600px)")

	const handleSliderChange = (event, newValue) => {
		map.layers.items[0].opacity = newValue / 100
		setSliderValue(newValue)
	}

	useEffect(() => {
		if (globalID) {
			setLayerLoading(true)
			map.removeAll()

			maps
				.queryFeatures({
					where: `Sudarytas = '${globalID}' AND Grupe = 'Istorinių topografijų rekonstrukcijos'`,
					outFields: ["Nuoroda", "Sudarytas"],
					returnGeometry: false,
				})
				.then((response) => {
          let subLayer
          let urlNew
          const urlSplit = response.features[0].attributes.Nuoroda.split("/")
          subLayer = parseInt(urlSplit.slice(-1))
          urlNew = urlSplit.slice(0, -1).join("/")
  
          const foundPeriod = new MapImageLayer({
            url: urlNew,
            title: `${response.features[0].attributes.Nuoroda}`,
            metai: response.features[0].attributes.Sudarytas,
            sublayers: [{ id: subLayer }],
          })

					map.add(foundPeriod)
					setLayerLoading(false)

					foundPeriod
						.when(() => {
							const oldOpacity = sliderValue
							setSliderValue(100)
							handleSliderChange({}, oldOpacity)
							return foundPeriod.queryExtent()
						})
						.then((response) => {
							view.constraints.geometry = {
								type: "extent",
								spatialReference: response.extent.spatialReference,
								xmin: response.extent.xmin,
								ymin: response.extent.ymin,
								xmax: response.extent.xmax,
								ymax: response.extent.ymax,
							}
						})
				})
		}
	}, [globalID])

	useEffect(() => {
		return () => {
			setSliderValue(100)

			map.removeAll()
			map.add(objects)

			objects
				.when(() => {
					return objects.queryExtent()
				})
				.then((response) => {
					view.constraints.geometry = {
						type: "extent",
						spatialReference: response.extent.spatialReference,
						xmin: response.extent.xmin,
						ymin: response.extent.ymin,
						xmax: response.extent.xmax,
						ymax: response.extent.ymax,
					}
				})
		}
	}, [])

	return (
		<>
			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={layerLoading}>
				<DNRSpinner />
			</Backdrop>
			<Grid
				variant="compareTimelineButtons"
				container
				direction="row"
				justifyContent="center"
				alignItems="flex-start"
			>
				<ButtonGroup>
					<Button
						sx={{
							background: globalID === "1808" ? "#55AFB0" : "white",
							color: globalID === "1808" ? "white" : "black",
							"&:hover": {
								backgroundColor: "#55AFB0",
								color: "white",
							},
							width: isMobile ? "auto" : 50,
						}}
						size="large"
						variant="timeline"
						onClick={() => {
							navigate(`/vilniausdnr/${i18n.language}/periods/compare/timeline/1808`)
						}}
					>
						<Typography variant="button">1808</Typography>
					</Button>
					<Button
						sx={{
							background: globalID === "1845" ? "#407D5C" : "white",
							color: globalID === "1845" ? "white" : "black",
							"&:hover": {
								backgroundColor: "#407D5C",
								color: "white",
							},
							width: isMobile ? "auto" : 50,
						}}
						size="large"
						variant="timeline"
						onClick={() => {
							navigate(`/vilniausdnr/${i18n.language}/periods/compare/timeline/1845`)
						}}
					>
						<Typography variant="button">1845</Typography>
					</Button>
					<Button
						sx={{
							background: globalID === "1911" ? "#007FCC" : "white",
							color: globalID === "1911" ? "white" : "black",
							"&:hover": {
								backgroundColor: "#007FCC",
								color: "white",
							},
							width: isMobile ? "auto" : 50,
						}}
						size="large"
						variant="timeline"
						onClick={() => {
							navigate(`/vilniausdnr/${i18n.language}/periods/compare/timeline/1911`)
						}}
					>
						<Typography variant="button">1911</Typography>
					</Button>
					<Button
						sx={{
							background: globalID === "1938" ? "#823F86" : "white",
							color: globalID === "1938" ? "white" : "black",
							"&:hover": {
								backgroundColor: "#823F86",
								color: "white",
							},
							width: isMobile ? "auto" : 50,
						}}
						size="large"
						variant="timeline"
						onClick={() => {
							navigate(`/vilniausdnr/${i18n.language}/periods/compare/timeline/1938`)
						}}
					>
						<Typography variant="button">1938</Typography>
					</Button>
					<Button
						sx={{
							background: globalID === "1977" ? "#EE5066" : "white",
							color: globalID === "1977" ? "white" : "black",
							"&:hover": {
								backgroundColor: "#EE5066",
								color: "white",
							},
							width: isMobile ? "auto" : 50,
						}}
						size="large"
						variant="timeline"
						onClick={() => {
							navigate(`/vilniausdnr/${i18n.language}/periods/compare/timeline/1977`)
						}}
					>
						<Typography variant="button">1977</Typography>
					</Button>
					<Button
						sx={{
							background: globalID === "2023" ? "#FFAF28" : "white",
							color: globalID === "2023" ? "white" : "black",
							"&:hover": {
								backgroundColor: "#FFAF28",
								color: "white",
							},
							width: isMobile ? "auto" : 50,
						}}
						size="large"
						variant="timeline"
						onClick={() => {
							navigate(`/vilniausdnr/${i18n.language}/periods/compare/timeline/2023`)
						}}
					>
						<Typography variant="button">2023</Typography>
					</Button>
					<Button
						sx={{
							background: globalID === "2024" ? "#FFAF28" : "white",
							color: globalID === "2024" ? "white" : "black",
							"&:hover": {
								backgroundColor: "#FFAF28",
								color: "white",
							},
							width: isMobile ? "auto" : 50,
						}}
						size="large"
						variant="timeline"
						onClick={() => {
							navigate(`/vilniausdnr/${i18n.language}/periods/compare/timeline/2024`)
						}}
					>
						<Typography variant="button">2024</Typography>
					</Button>
				</ButtonGroup>
			</Grid>
			<Grid
				sx={{ ml: isMobile ? "inherit" : -2.25 }}
				variant="compareType"
				container
				direction="row"
				justifyContent={isMobile ? "left" : "center"}
				alignItems="flex-start"
			>
				<Box sx={{ mt: 9, ml: 2, width: 206, height: 45, borderRadius: 10, backgroundColor: "white" }}>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Typography sx={{ mt: 0.4, mb: -1.4 }}>{t("sys.transparency")}</Typography>
					</Grid>
					<Slider
						sx={{ ml: "10%", width: "80%", "& .MuiSlider-markLabel": { top: "-10px" } }}
						value={sliderValue}
						min={0}
						max={100}
						size="small"
						valueLabelDisplay="auto"
						onChange={handleSliderChange}
					/>
				</Box>
			</Grid>
		</>
	)
}

export default CompareTimeline
