import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import SvgIcon from "@mui/material/SvgIcon"
import useMediaQuery from "@mui/material/useMediaQuery"

import { ReactComponent as timelineIcon } from "../../../utils/icons/compareTypeIcons/laikojuosta.svg"
import { ReactComponent as swipeIcon } from "../../../utils/icons/compareTypeIcons/slenkanti.svg"
import { ReactComponent as windowIcon } from "../../../utils/icons/compareTypeIcons/dulangai.svg"

const CompareType = (props) => {
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()

	const isMobile = useMediaQuery("(min-width:600px)")

	const intToPeriod = {
		0: "1808",
		1: "1845",
		2: "1911",
		3: "1938",
		4: "1977",
		5: "2023",
		6: "2024",
	}

	const handleCompareChange = (event) => {
		props.setSelectedCompare(event.target.value)

		switch (event.target.value) {
			case 0:
				navigate(
					`/vilniausdnr/${i18n.language}/periods/compare/timeline/` +
						intToPeriod[props.selectedPeriods[0]]
				)
				break

			case 1:
				navigate(`/vilniausdnr/${i18n.language}/periods/compare/swipe`)
				break

			case 2:
				navigate(`/vilniausdnr/${i18n.language}/periods/compare/window`)
				break
		}
	}

	useEffect(() => {
		switch (true) {
			case window.location.href.includes("timeline"):
				props.setSelectedCompare(0)
				break
			case window.location.href.includes("swipe"):
				props.setSelectedCompare(1)
				break
			case window.location.href.includes("window"):
				props.setSelectedCompare(2)
				break
		}
	}, [])

	return (
		<Grid
			sx={{ ml: isMobile ? "inherit" : -2.25 }}
			variant="compareType"
			container
			direction="row"
			justifyContent={isMobile ? "left" : "center"}
			alignItems="flex-start"
		>
			<FormControl
				sx={{
					width: "auto",
					height: "45px",
					boxShadow: 0,
					mt: 2,
					ml: 2,
				}}
				variant="outlined"
				size="small"
				id="swipe-select"
			>
				<Select
					sx={{ minWidth: 206 }}
					value={props.selectedCompare}
					onChange={handleCompareChange}
					renderValue={(value) => (
						<Typography sx={{ color: "#D72E30" }} component={"div"} align="center">
							<Typography sx={{ color: "black", display: "inline" }}>{t("sys.mode")}: </Typography>
							{value === 0 ? t("sys.timeline") : value === 1 ? t("sys.swipe") : t("sys.windows")}
						</Typography>
					)}
				>
					<MenuItem
						sx={{
							display: "flex",
							whiteSpace: "unset",
							"&.Mui-selected": {
								color: "#D72E30",
								backgroundColor: "#F7D5D6",
							},
							px: 1,
						}}
						key={0}
						value={0}
					>
						<SvgIcon sx={{ my: -2, fontSize: 35, mr: 1 }} component={timelineIcon} inheritViewBox />
						<Typography sx={{ width: "100%" }} align="center">
							{t("sys.timeline")}
						</Typography>
					</MenuItem>

					<MenuItem
						sx={{
							display: "flex",
							whiteSpace: "unset",
							"&.Mui-selected": {
								color: "#D72E30",
								backgroundColor: "#F7D5D6",
							},
							px: 1,
						}}
						key={1}
						value={1}
					>
						<SvgIcon sx={{ my: -2, fontSize: 35, mr: 1 }} component={swipeIcon} inheritViewBox />
						<Typography sx={{ width: "100%" }} align="center">
							{t("sys.swipe")}
						</Typography>
					</MenuItem>
					<MenuItem
						sx={{
							display: "flex",
							whiteSpace: "unset",
							"&.Mui-selected": {
								color: "#D72E30",
								backgroundColor: "#F7D5D6",
							},
							px: 1,
						}}
						key={2}
						value={2}
					>
						<SvgIcon sx={{ my: -2, fontSize: 35, mr: 1 }} component={windowIcon} inheritViewBox />
						<Typography sx={{ width: "100%" }} align="center">
							{t("sys.windows")}
						</Typography>
					</MenuItem>
				</Select>
			</FormControl>
		</Grid>
	)
}

export default CompareType
