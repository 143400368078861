import React from "react"
import { useTranslation } from "react-i18next"
import { objects } from "../../../../utils/streetsArcgisItems"

import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import ColorLensIcon from "@mui/icons-material/ColorLens"

const Legends = (props) => {
	const { t, i18n } = useTranslation()
	const uniqueValues = objects.renderer.uniqueValueInfos

	return (
		<Grid variant="optionsTabs">
			<Typography sx={{ m: 1, mb: 0 }} variant="subtitle1">
				{t("sys.legend") + ":"}
			</Typography>
			<List>
				{uniqueValues.map((legend, i) => (
					<div key={i}>
						<ListItem sx={{ my: 0.3 }} disablePadding>
							<ColorLensIcon
								sx={{
									ml: 2,
									mr: 2,
									fontSize: 35,
									color: `rgba(${legend.symbol.color.r},${legend.symbol.color.g},${legend.symbol.color.b},${legend.symbol.color.a})`,
								}}
							/>
							<Typography sx={{ mr: 1 }} variant="body2">
								{t(`streets.class.${props.initialObjectsClasses[0][legend.value - 1].code}`)}
							</Typography>
						</ListItem>
						{i !== uniqueValues.length - 1 && <Divider light variant="middle" />}
					</div>
				))}
			</List>
		</Grid>
	)
}

export default Legends
