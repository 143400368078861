import React, { useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import * as watchUtils from "@arcgis/core/core/watchUtils"
import Point from "@arcgis/core/geometry/Point"

import { view, objects, bgExpand, locateWidget } from "../../../utils/partsArcgisItems"

const viewHandles = []

const ObjectMap = (props) => {
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()
	const mapDiv = useRef(null)

	useEffect(() => {
		view.container = mapDiv.current

		let pt = new Point({
			x: 582527.5805600522,
			y: 6061855.557955307,
			spatialReference: {
				wkid: 2600,
			},
		})
		view.center = pt

		viewHandles.forEach((handle) => {
			handle.remove()
		})
		viewHandles.length = 0

		objects.visible = false

		view.whenLayerView(objects).then((objectsView) => {
			watchUtils.whenFalseOnce(objectsView, "updating").then(() => {
				objects
					.queryFeatures({
						outFields: ["*"],
						where: "",
						returnGeometry: true,
					})
					.then((response) => {
						if (response.features.length) {
							props.setInitialObjectsList(response.features)
							props.setInitialLoading(false)
						}
					})
					.catch((error) => {
						console.error(error)
					})
			})
		})
	}, [])

	useEffect(() => {
		bgExpand.content.source.basemaps.items[0].title = t("sys.basemapLight")
		bgExpand.content.source.basemaps.items[1].title = t("sys.basemapDark")
		bgExpand.content.source.basemaps.items[2].title = t("sys.basemapOrto")

		view.ui.empty("top-left")

		view.ui.add(bgExpand, "top-left")
		view.ui.add(locateWidget, "top-left")
	}, [i18n.language])

	useEffect(() => {
		return () => {
			viewHandles.forEach((handle) => {
				handle.remove()
			})
			viewHandles.length = 0

			view.container = null
		}
	}, [])

	return <div className="map" ref={mapDiv}></div>
}

export default ObjectMap
