import React, { useState, useEffect } from "react"
import { Routes, Route, Outlet, useLocation, useNavigate } from "react-router-dom"

import ObjectMap from "../components/Periods/map/ObjectMap"
import CompareToggle from "../components/Periods/compare/CompareToggle"
import InfoTooltip from "../utils/misc/InfoTooltip"
import "../css/signs.css"

import Grid from "@mui/material/Grid"

const Periods = () => {
	const [toggleCompareWindow, setToggleCompareWindow] = useState(false)
	const [historyToggle, setHistoryToggle] = useState(false)
	const [once, setOnce] = useState(false)

	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)

	let location = useLocation()
	let navigate = useNavigate()

	useEffect(() => {
		const infoButton = document.getElementById("info_button")
		setAnchorEl(infoButton)
		setOpen(true)
		setTimeout(() => {
			setOpen(false)
		}, 4000)
	}, [])

	useEffect(() => {
		if (!location.pathname.includes("compare")) {
			navigate("compare/timeline")
		}
	}, [location])

	return (
		<Routes>
			<Route
				path="*"
				element={
					<>
						<Grid container spacing={0}>

							<Grid variant="compareMain" item xs>
								<ObjectMap
									toggleCompareWindow={toggleCompareWindow}
								/>
								<CompareToggle
									setToggleCompareWindow={setToggleCompareWindow}
									historyToggle={historyToggle}
									setHistoryToggle={setHistoryToggle}
									once={once}
									setOnce={setOnce}
								/>
								<Outlet />
							</Grid>
						</Grid>
						<InfoTooltip open={open} setOpen={setOpen} anchorEl={anchorEl} />
					</>
				}
			>
			</Route>
		</Routes>
	)
}

export default Periods
