import React, { useEffect, useState, useRef, useMemo, useCallback } from "react"
import ForceGraph2D from "react-force-graph-2d"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { related_persons } from "../../../utils/personsArcgisItems"

import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

const ForceGraph = (props) => {
	const [graphData, setGraphData] = useState({ nodes: [], links: [] })
	const [graph2DRendered, setGraph2DRendered] = useState(false)
	const graph2DRef = useRef(null)

	const navigate = useNavigate()
	const { t, i18n } = useTranslation()

	const getLangName = (nameLT, nameEN, nameRU, namePL, mainLanguage, nameOther) => {
		let name

		if (i18n.language === "lt") {
			name = nameLT
		} else {
			if (i18n.language === "en" && nameEN) {
				name = nameEN
			} else if (i18n.language === "ru" && nameRU) {
				name = nameRU
			} else if (i18n.language === "pl" && namePL) {
				name = namePL
			} else {
				switch (mainLanguage) {
					case "LT":
						name = nameLT
						break
					case "EN":
						name = nameEN
						break
					case "RU":
						name = nameRU
						break
					case "PL":
						name = namePL
						break
					default:
						name = nameOther
				}
			}
		}

		if (!name) {
			name = nameLT
		}

		return name
	}

	const handleNodeClick = (node) => {
		navigate(`/vilniausdnr/${i18n.language}/persons/${node.id.replace(/[{}]/g, "")}`)
	}

	useEffect(() => {
		if (graph2DRendered && graph2DRef.current) {
			graph2DRef.current.d3Force("charge").strength(-300).distanceMax(500)
			graph2DRef.current.zoom(0.35, 1000)
		}
	}, [graph2DRendered])

	useEffect(() => {
		related_persons
			.queryFeatures({
				outFields: [
					"Asmuo",
					"Susijes_asmuo_is_saraso",
					"Rysio_tipas",
					"Rysio_tipas_ANG",
					"Rysio_tipas_RUS",
					"Rysio_tipas_LEN",
				],
				where: "Susijes_asmuo_is_saraso is not null",
			})
			.then((response) => {
				setGraphData({ nodes: [], links: [] })
				setGraph2DRendered(false)
        
				const tempGraphData = { nodes: [], links: [] }
				const perRelateCount = {}

				for (let per of response.features) {
					const person1 = per.attributes.Asmuo
					const person2 = per.attributes.Susijes_asmuo_is_saraso

					if (
						props.tableObjectsList.some((obj) => obj.attributes.Asmenybes_ID === person1) &&
						props.tableObjectsList.some((obj) => obj.attributes.Asmenybes_ID === person2)
					) {
						if (person1 in perRelateCount) {
							perRelateCount[person1]++
						} else {
							perRelateCount[person1] = 1
						}

						if (person2 && person2 in perRelateCount) {
							perRelateCount[person2]++
							if (person2 === person1) {
								perRelateCount[person1]++
							}
						} else if (person2) {
							perRelateCount[person2] = 1
						}
					}
				}

				// console.log(Math.max(...Object.values(perRelateCount)))

				for (let per of props.tableObjectsList) {
					tempGraphData.nodes.push({
						id: per.attributes.Asmenybes_ID,
						name: getLangName(
							per.attributes.Vardas_lietuviskai,
							per.attributes.Vardas_pavarde_EN,
							per.attributes.Vardas_pavarde_RU,
							per.attributes.Vardas_pavarde_PL,
							per.attributes.Kuri_kalba_pagrindine,
							per.attributes.Vardas_pavarde_KITA
						),
						relates: perRelateCount[per.attributes.Asmenybes_ID]
							? perRelateCount[per.attributes.Asmenybes_ID]
							: 1,
					})
				}

				for (let per of response.features) {
					tempGraphData.links.push({
						source: per.attributes.Asmuo,
						target: per.attributes.Susijes_asmuo_is_saraso,
						name:
							i18n.language === "lt"
								? typeof per.attributes.Rysio_tipas === "string"
									? per.attributes.Rysio_tipas.charAt(0).toUpperCase() + per.attributes.Rysio_tipas.slice(1)
									: per.attributes.Rysio_tipas
								: i18n.language === "en"
								? typeof per.attributes.Rysio_tipas_ANG === "string"
									? per.attributes.Rysio_tipas_ANG.charAt(0).toUpperCase() +
									  per.attributes.Rysio_tipas_ANG.slice(1)
									: per.attributes.Rysio_tipas_ANG
								: i18n.language === "ru"
								? typeof per.attributes.Rysio_tipas_RUS === "string"
									? per.attributes.Rysio_tipas_RUS.charAt(0).toUpperCase() +
									  per.attributes.Rysio_tipas_RUS.slice(1)
									: per.attributes.Rysio_tipas_RUS
								: typeof per.attributes.Rysio_tipas_LEN === "string"
								? per.attributes.Rysio_tipas_LEN.charAt(0).toUpperCase() +
								  per.attributes.Rysio_tipas_LEN.slice(1)
								: per.attributes.Rysio_tipas_LEN,
						curvature: 0.15,
					})
				}

				const filteredLinks = tempGraphData.links.filter(
					(link) =>
						tempGraphData.nodes.some((node) => node.id === link.source) &&
						tempGraphData.nodes.some((node) => node.id === link.target)
				)

				if (filteredLinks.length === 0) {
					filteredLinks.push({
						source: tempGraphData.nodes[0].id,
						target: tempGraphData.nodes[0].id,
					})
				}

				tempGraphData.links = filteredLinks

				setGraphData(tempGraphData)
			})
	}, [props.tableObjectsList, i18n.language])

	const data = useMemo(() => {
		if (graphData.links.length > 0) {
			graphData.links.forEach((link) => {
				const a = graphData.nodes.find((node) => node.id === link.source)
				const b = graphData.nodes.find((node) => node.id === link.target)
				if (a && b) {
					!a.neighbors && (a.neighbors = [])
					!b.neighbors && (b.neighbors = [])
					a.neighbors.push(b)
					b.neighbors.push(a)

					!a.links && (a.links = [])
					!b.links && (b.links = [])
					a.links.push(link)
					b.links.push(link)
				}
			})
		}

		return graphData
	}, [graphData])

	const [highlightNodes, setHighlightNodes] = useState(new Set())
	const [highlightLinks, setHighlightLinks] = useState(new Set())
	const [hoverNode, setHoverNode] = useState(null)

	const updateHighlight = () => {
		setHighlightNodes(highlightNodes)
		setHighlightLinks(highlightLinks)
	}

	const handleNodeHover = (node) => {
		highlightNodes.clear()
		highlightLinks.clear()
		if (node) {
			highlightNodes.add(node)
			if (node?.neighbors?.length > 0) {
				node.neighbors.forEach((neighbor) => highlightNodes.add(neighbor))
			}
			if (node?.neighbors?.length > 0) {
				node.links.forEach((link) => highlightLinks.add(link))
			}
		}

		setHoverNode(node || null)
		updateHighlight()
	}

	const handleLinkHover = (link) => {
		highlightNodes.clear()
		highlightLinks.clear()

		if (link) {
			highlightLinks.add(link)
			highlightNodes.add(link.source)
			highlightNodes.add(link.target)
		}

		updateHighlight()
	}

	const paintRing = useCallback(
		(node, ctx) => {
			ctx.beginPath()
			ctx.arc(
				node.x,
				node.y,
				Math.sqrt(node.relates * 4) + (4 - (4 / 64) * node.relates),
				0,
				2 * Math.PI,
				false
			)
			ctx.fillStyle = node === hoverNode ? "green" : "orange"
			ctx.fill()
		},
		[hoverNode]
	)

	return (
		<>
			<Container variant="graph">
				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="flex-start"
					sx={{
						position: "absolute",
						zIndex: "10",
						width: "100%",
						backgroundColor: "rgba(235, 235, 235, 0.5)",
					}}
				>
					<Typography variant="body2">{t("sys.selectPersonGraph")}</Typography>
				</Grid>
				{graphData.nodes.length > 0 && graphData.links.length > 0 && (
					<ForceGraph2D
						ref={(el) => {
							graph2DRef.current = el
							setGraph2DRendered(true)
						}}
						graphData={graphData}
						height={window.innerHeight - 90}
						backgroundColor={["lightgray"]}
						nodeColor={["#D72E30"]}
						linkColor={["gray"]}
						onNodeClick={handleNodeClick}
						nodeRelSize={4}
						nodeVal={(node) => Math.sqrt(node.relates * 4)}
						linkCurvature="curvature"
						linkDirectionalArrowLength={4}
						linkDirectionalArrowRelPos={1}
						autoPauseRedraw={false}
						linkWidth={(link) => (highlightLinks.has(link) ? 3 : 1)}
						linkDirectionalParticles={4}
						linkDirectionalParticleWidth={(link) => (highlightLinks.has(link) ? 4 : 0)}
						onNodeHover={handleNodeHover}
						onLinkHover={handleLinkHover}
						nodeCanvasObjectMode={() => "after"}
						nodeCanvasObject={(node, ctx, globalScale) => {
							ctx.beginPath()
							ctx.arc(
								node.x,
								node.y,
								Math.sqrt(node.relates * 4) + (4 - (4 / 64) * node.relates),
								0,
								2 * Math.PI,
								false
							)
							ctx.lineWidth = 1
							ctx.strokeStyle = "black"
							ctx.stroke()

							if (globalScale > 1.5) {
								const label = node.name
								const fontSize = 13 / globalScale

								// ctx.fillStyle = "white"
								// ctx.fillRect(
								// 	node.x - ctx.measureText(label).width / 2,
								// 	node.y + 4 + (node.relates ? node.relates + 4 : 4),
								// 	ctx.measureText(label).width,
								// 	fontSize / 2
								// )
								// ctx.fillStyle = "white"
								// ctx.fillRect(
								// 	node.x - ctx.measureText(label).width / 2,
								// 	node.y + 4 + (node.relates ? node.relates + 4 : 4),
								// 	ctx.measureText(label).width,
								// 	-fontSize / 1.5
								// )

								ctx.font = `${fontSize}px Roboto`
								ctx.textAlign = "center"
								ctx.textBaseline = "middle"
								ctx.fillStyle = "black"
								ctx.fillText(
									label,
									node.x,
									node.y + 4 + Math.sqrt(node.relates * 4) + (4 - (4 / 64) * node.relates)
								)
							}

							if (highlightNodes.has(node)) {
								paintRing(node, ctx)
							}
						}}
						// linkCanvasObjectMode={() => "after"}
						// linkCanvasObject={(link, ctx, globalScale) => {
						// 	if (globalScale > 1.5 && highlightLinks.has(link)) {
						// 		const label = link.name.charAt(0).toUpperCase() + link.name.slice(1)
						// 		// const textWidth = ctx.measureText(label).width
						// 		const fontSize = 11 / globalScale

						// 		// Position the label in the middle of the link
						// 		const x = (link.source.x + link.target.x) / 2
						// 		const y = (link.source.y + link.target.y) / 2

						// 		// Draw the label background
						// 		// ctx.fillStyle = "white"
						// 		// ctx.fillRect(x - textWidth / 2 - 4, y - fontSize / 2 - 4, textWidth, fontSize)

						// 		// Draw the label text
						// 		ctx.font = `${fontSize}px Roboto`
						// 		ctx.fillStyle = "black"
						// 		ctx.fillText(label, x, y)
						// 	}
						// }}
					/>
				)}
			</Container>
		</>
	)
}

export default ForceGraph
