import React, { useState, useEffect } from "react"
import { Routes, Route, Outlet } from "react-router-dom"

import ObjectMap from "../components/Maps/map/ObjectMap"
import CompareToggle from "../components/Maps/compare/CompareToggle"
import InfoTooltip from "../utils/misc/InfoTooltip"
import "../css/signs.css"

import Grid from "@mui/material/Grid"

const Maps = () => {
	const [initialLoading, setInitialLoading] = useState(false)
	const [initialLoadingOnce, setInitialLoadingOnce] = useState(true)
	const [toggleCompareWindow, setToggleCompareWindow] = useState(false)
	const [historyToggle, setHistoryToggle] = useState(false)
	const [once, setOnce] = useState(false)

	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)

	useEffect(() => {
		if (initialLoading && initialLoadingOnce) {
			setInitialLoadingOnce(false)
			const infoButton = document.getElementById("info_button")
			setAnchorEl(infoButton)
			setOpen(true)
			setTimeout(() => {
				setOpen(false)
			}, 4000)
		}
	}, [initialLoading])

	return (
		<>
			<Routes>
				<Route
					path="*"
					element={
						<>
							<Grid container spacing={0}>
								<Grid variant="compareMain" item xs>
									<ObjectMap
										toggleCompareWindow={toggleCompareWindow}
									/>
									<CompareToggle
										setInitialLoading={setInitialLoading}
										setToggleCompareWindow={setToggleCompareWindow}
										historyToggle={historyToggle}
										setHistoryToggle={setHistoryToggle}
										once={once}
										setOnce={setOnce}
									/>
									<Outlet />
								</Grid>
							</Grid>
							<InfoTooltip open={open} setOpen={setOpen} anchorEl={anchorEl} />
						</>
					}
				></Route>
			</Routes>
		</>
	)
}

export default Maps
