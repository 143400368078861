import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

const resources = {
	lt: {
		translation: {
			help: {
				first: {
					about: "Apie projektą",
					usage: "Naudojimo instrukcija",
					contacts: "Kontaktai",
				},
				third: {
					info: "Pateikiama informacija",
					how: "Kaip naudotis?",
				},
			},
			sys: {
				review: "Peržiūra",
				swipe: "Slenkanti juosta",
				windows: "Du langai",
				timeline: "Laiko juosta",
				present: "Dabartis",
				past: "Praeitis",
				history: "Istorija",
				search: "Paieška",
				visualize: "Vaizdavimas",
				visualizeBy: "Vaizduoti pagal",
				objectType: "Objekto tipą",
				memoryType: "Atminimo tipą",
				legend: "Sutartiniai ženklai",
				all: "Visi",
				objectClass: "Objekto klasė",
				objectSubclass: "Objekto poklasė",
				results: "Rezultatai",
				resultsNum: "Rezultatų skaičius",
				clearFilters: "Išvalyti filtrus",
				period: "Įrengimo laikotarpis",
				extent: "Sąraše rodyti tik žemėlapio aprėptyje matomus objektus",
				shareUrl: "Nuoroda nukopijuota",
				basemapLight: "Šviesus žemėlapis",
				basemapDark: "Tamsus žemėlapis",
				basemapOrto: "Ortofoto",
				activity: "Veikla",
				mode: "Režimas",
				transparency: "Permatomumas",
				profession: "Profesija",
				more: "Plačiau",
				collapse: "Suskleisti",
				leftMap: "Kairys žemėlapis",
				rightMap: "Dešinys žemėlapis",
				swipeText: "Slinkite juostą ir lyginkite abu žemėlapius",
				dna: "DNR",
				year: "Metai",
				notFound: "Nerasta",
				map: "Žemėlapis",
				selectPersonMobile: "Pasirinkite konkrečią asmenybę iš sąrašo",
				selectPersonGraph:
					"Pasirinkite konkrečią asmenybę iš sąrašo kairėje arba naudokite interaktyvųjį grafą",
			},
			fields: {
				activityProfession: "Veikla profesija",
				activityBrief: "Veikla (kūryba) trumpai",
				personConnection: "Asmens ryšys su Vilniumi",
				awards: "Apdovanojimai",
				objectName: "Objekto pavadinimas",
				objectText: "Užrašas ant objekto",
				objectDesc: "Objekto aprašymas",
				event: "Istorinis įvykis",
				mapName: "Žemėlapio pavadinimas",
				mapDesc: "Žemėlapio aprašymas",
				group: "Grupė",
				desc: "Aprašymas",
				type: "Tipas",
				scale: "Mastelis",
				coord: "Koordinatės",
				made: "Sudarytas",
				source: "Šaltinis",
				sources: "Šaltiniai",
				name: "Pavadinimas",
				class: "Klasė",
				subclass: "Poklasis",
				personRelated: "Susijęs asmuo",
				streetNames:
					"Istoriniuose žemėlapiuose pateikiami gatvės ar jos dalies pavadinimai (originalia forma)",
				author: "Autorius",
				sourceDetailed: "Šaltiniai (Autorius, pavadinimas, int. puslapis)",
				eventDate: "Įvykio data",
				place: "Vieta",
				personalities: "Asmenybės",
				orgRelated: "Susijusios organizacijos",
				streets: "Gatvės",
				burial: "Palaidojimas",
				family: "Šeima",
				researched: "Domėjosi, tyrinėjo ar vertė kūrybą",
				friends: "Draugai, politiniai bendražygiai ir bendradarbiai",
				ortoMaps: "Ortofotografiniai žemėlapiai",
				libMaps: "Vrublevskių bibliotekos žemėlapiai",
				topoHistoryMaps: "Istorinių topografijų rekonstrukcijos",
				topoMaps: "Topografiniai žemėlapiai",
				sculptures: "Skulptūros ir paminklai",
				plaques: "Atminimo lentos",
				eventRelated: "Susiję įvykiai",
				personsRelated: "Susijusios asmenybės",
				familyRelated: "Asmenybės ryšys su šeimos nariais",
				other: "Kiti",
				eventsTimeline: "Istorinių įvykių juosta",
				mapsRelated: "Susiję žemėlapiai",
				plaquesRelated: "Susijusios skulptūros ir atminimo lentos",
				century: "a.",
				originalMaps: "Originalūs naudoti žemėlapiai",
				nickname: "Slapyvardis",
				photosRelated: "Susijusios nuotraukos",
				streetLength: "Gatvės ilgis",
				currentStreet: "Dabartiniai gatvės ar jos dalies pavadinimai",
				streetsRelated: "Susijusi gatvė",
				sculptor: "Skulptorius",
				architect: "Architektas",
				additionalInfo: "Papildoma informacija",
        constructor: "Konstruktorius",
			},
			home: {
				periods: "Miestas atskirais istoriniais periodais",
				plaques: "Skulptūros ir atminimo lentos",
				persons: "Mieste įamžintos asmenybės",
				maps: "Istoriniai planai ir žemėlapiai",
				streets: "Miesto gatvės",
				parts: "Miesto dalys",
				foto: "Senosios fotografijos ir atvirutės",
				buildings: "Pastatai",
				events: "Istoriniai įvykiai",
				more: {
					periods:
						"Skiltyje pateikiamos 5 rekonstruotos istorinės topografijos, reprezentuojančios Vilnių skirtingais istorijos periodais",
					plaques:
						"Skiltyje pateikiama informacija apie mieste esančias atminimo lentas, skulptūras ir kitus meninius ar mažosios architektūros objektus",
					persons:
						"Skiltyje pateikiama informacija apie asmenybes, kurioms Vilniaus mieste įrengtos atminimo lentos, skulptūros, paminklai ar jų vardais pavadintos gatvės",
					maps: "Skiltyje pateikiami istoriniai Vilniaus miesto planai, žemėlapiai, ortofotografinės nuotraukos ir kita kartografinė medžiaga",
					streets:
						"Skiltyje pateikiama informacija apie visas Vilniaus miesto gatves. Kartu pateikiama informacija apie centrinės Vilniaus miesto dalies gatvių istoriją - 5 istorinių periodų pavadinimus ir ašines linijas",
					parts:
						"Skiltyje pateikiama informacija apie Vilniaus miesto dalis - seniūnijas, seniūnaitijas, rajonus bei istorinius įvairių laikmečių vietovardžius",
					foto: "Skiltyje pateikiamos istorinės Vilniaus miesto fotografijos ir atvirutės",
					buildings:
						"Skiltyje pateikiama informacija apie Vilniaus miesto pastatus, jų statybos metus, autorius bei fundatorius",
					events: "Skiltyje pateikiama informacija apie svarbiausius Vilniaus miestui istorinius įvykius",
				},
				welcome1:
					"Atverkite virtualius Vilniaus istorijos vartus ir pažinkite sostinę tyrinėdami istorinius žemėlapius, senąsias fotografijas bei svarbiausius miesto įvykius.",
				welcome2: "Kviečiame nagrinėti ir pažinti Vilniaus miesto DNR!",
			},
			plaques: {
				objects: {
					1: "Atminimo arba memorealinė lenta su tekstu",
					2: "Skulptūra, paminklas, skulptūrinė kompozicija",
					3: "Atminimo lenta su bareljefu ar kitais meniniais elementais",
					4: "Freska, pano, mozaika, sieninė tapyba",
					5: "Pavadinimo lentelė (pavadinimas, kuris suteiktas pastatui, statiniui ar kitam objektui)",
					6: "Užrašas, ženklas grindinyje, ant laiptų, akmenų",
					7: "Vietos pažymėjimo ženklas mažosios architektūros priemonėmis",
					8: "Paminklas, antkapis palaidojimo vietose",
				},
				memories: {
					1: "Žymaus asmens atminimui",
					2: "Žymių asmenų grupės atminimui",
					3: "Abstraktus objektas",
					4: "Organizacijos atminimui",
					5: "Reikšmingo (istorinio) įvykio atminimui",
					6: "Žymaus asmens palaidojimo vietai žymėti",
					7: "Meninis idėjos simbolis",
					8: "Istorinio statinio vietos pažymėjimas",
				},
				periods: {
					1: "Iki 1795 m. (Respublikos padalinimo)",
					2: "1796 - 1919 m. (Iki lenkų okupacijos)",
					3: "1919 - 1939 m.",
					4: "1939 - 1989 m. (Sovietmečiu)",
					5: "nuo 1990 m. (Atkūrus nepriklausomybę)",
				},
			},
			persons: {
				profession: {
					1: "Nusipelnę kovoje už Lietuvą",
					2: "Menininkas, kūrėjas",
					3: "Mokslininkas, mokslo darbuotojas, tyrinėtojas (be technologijų)",
					4: "Technologijų kūrėjai, meistrai, inžinieriai",
					5: "Švietimo, visuomenės sveikatos , Kultūros, socialinio gyvenimo organizatoriai",
					6: "Dvasininkai, misionieriai, šventieji",
					7: "Lietuvos valstybės (demokratinės) vadovai, politikai, valdymo organizatoriai",
					8: "Istoriniai Lietuvos valstybės valdovai, valdytojai, dvarininkai",
					9: "Amatininkai",
					10: "Kitų kraštų, šalių, tautų valdovai, valdytojai, valdymo organizatoriai, politikai",
					11: "Kiti",
				},
				activity: {
					11: "Paaukoję gyvybę kovoje Lietuvos už laisvę",
					12: "Lietuvos kariai, partizanai žuvę ginkluotoje kovoje (XX a)",
					13: "Žuvę, nukankinti politiniai kaliniai (XX a)",
					14: "Žuvę nepriklausomybės karų, sukilimo dalyviai",
					15: "Sukilėliai už kovotojai Lietuvos laisvę, prieš caro režimą",
					16: "Švietėjas, knygnešys, leidėjas (iki XX a.)",
					17: "Ginkluotos kovos prieš XX okupacijas dalyvis",
					18: "Lietuvos žmonės tremtiniai, politiniai kaliniai",
					19: "Lietuvos atgimimo sąjūdžio veikėjai (nuo 1987 m.)",
					21: "Dailininkas, tapytojas, grafikas, fotografas",
					22: "Architektas, skulptorius, dizaineris, lėlininkas",
					23: "Muzikas instrumentalistas, dirigentas, kompozitorius",
					24: "Muzikas vokalistas, dainininkas",
					25: "Teatro, kino, operos, baleto aktorius, šokėjas",
					26: "Poetas, poezijos vertėjas",
					27: "Rašytojas, dramaturgas, žurnalistas, publicistas",
					28: "Aktorius, režisierius",
					29: "Liaudies menininkas",
					31: "Humanitariniai mokslai: Kalbininkas, muzikologas, teatrologas, dailėtyrininkas, semiotikas, literatūrologas",
					32: "Istorijos mokslai: Istorikas, kraštotyrininkas, archeologas, etnologas",
					33: "Edukologija, psichologija, profesoriai, docentai",
					34: "Ekonomistas, sociologas, psichologas, teisininkas, politologas, vadybos teoretikas (kiti socialiniai mokslai:",
					35: "Biologija, medicina. Ekologas, Biochemikas, zoologas, botanikas",
					36: "Fizikas, chemikas, geologas, geografas, geofizikas, astronomas, hidrologas",
					37: "Matematika, Logika, Informatika",
					38: "Filosofija, teologija",
					39: "Kiti mokslai",
					41: "Augalininkystės ir gyvulininkystės technologijos: bioinžinerija, selekcija: agronomai, veterinarai",
					42: "Maisto pramonės technologijos, biotechnologijos",
					43: "Lengvosios pramonės technologijos: medžio, audinių, kitų medžiagų gamybos",
					44: "Mechanikos inžinerija, mašinos, autotransportas, aviacija, konstruktoriai",
					45: "Energetikos, elektros, elektronikos inžinerija",
					46: "Skaičiavimo technika ir technologijos",
					47: "Medžiagų inžinerija ir technologijos",
					48: "Statyba, statybų technologijos kelių, tiltų, statyba, aplinkos pertvarkymo inžinerija",
					49: "Ekonomika",
					51: "Pedagogas, mokytojas, dėstytojas, auklėtojas",
					52: "Kultūros darbuotojas",
					53: "Gydytojas, sveikatos apsaugos darbuotojas",
					54: "Tautų gelbėtojas",
					56: "Sportininkas, sporto organizatorius",
					57: "Keliautojas, alpinistas, fotografas, publicistas, žurnalistas",
					58: "Įvairių verslų organizatorius (turizmo, senųjų amatų, žvejybos, medžioklės)",
					59: "Valstybės tarnautojas",
					61: "Žyniai, šventieji",
					62: "Katalikų kunigas, klebonas, pastorius, misionierius, vienuolis",
					63: "Popiežius, kardinolas, arkivyskupas, vyskupas, arkidiakonas",
					64: "Evangelikų (liuteronų ir protestantų) dvasininkas",
					65: "Stačiatikių, sentikių, unitų dvasininkas, vienuolis",
					66: "Judaizmo dvasininkas, rabinas",
					67: "Karaimų dvasininkas",
					68: "Islamo dvasininkas",
					69: "Kitų religijų dvasininkai",
					71: "Lietuvos Respublikos aukščiausi pareigūnai (Prezidentas, Seimo pirmininkas, Ministras pirmininkas)",
					72: "Lietuvos ministerijų ir valstybės institucijų vadovai",
					73: "Vilniaus miesto merai, vicemerai, adm. direktoriai",
					74: "Lietuvos valstybės aparato tarnautojai",
					75: "Teisėjai, advokatai, policininkai kiti teisėsaugos pareigūnai",
					76: "Krašto apsaugos pareigūnai, karininkai, generolai",
					77: "Krašto apsaugos eiliniai kariai, partizanai",
					78: "Lietuvos politikai, diplomatai",
					79: "Verslo, gamybos organizatorius, vadybininkas, menedžeris, direktorius",
					81: "LDK valdovas (Didysis kunigaikštis)",
					82: "Kunigaikščiai, aukštieji LDK didikai (iki Unijos)",
					83: "Aukštieji LDK didikai, (po Unijos) etmonai, karvedžiai",
					84: "Kiti LDK didikai, dvarininkai",
					85: "Rusijos imperijos vietininkai, pareigūnai statitiniai",
					86: "Kitų okupacinių režimų pareigūnai statiniai",
					87: "",
					88: "",
					91: "Restauratoriai, pastatų, interjero, baldų, dailės ir t.t.",
					92: "Metalų liejimo meistrai (varpų, patrankų)",
					93: "Kalviai, račiai, ginklakaliai",
					94: "Baldžiai, staliai, stikliai",
					95: "Siuvėjai, kirpėjai, kurpiai",
					96: "Auksakaliai,",
					97: "Žolininkai, senieji vaistininkai",
					98: "Gentiniai vadai (ne lietuvių)",
					99: "Užsienio šalių aukščiausi pareigūnai (Prezidentas, Seimo pirmininkas, Ministras pirmininkas)",
					100: "Užsienio šalių ministerijų ir valstybės institucijų vadovai",
					101: "Miškininkai, girininkai, sodininkai, gėlininkai",
					102: "Žuvininkai",
					103: "Bitininkai",
					104: "Užsienio šalių politikai, diplomatai",
					105: "Karo inžinerija",
					106: "Spaustuvininkai",
					107: "Užsienio šalių krašto apsaugos pareigūnai, karininkai, generolai",
					111: "Kiti",
				},
			},
			streets: {
				class: {
					1: "Asmenvardžiai",
					2: "Vilniaus ir Lietuvos vietovardžiai",
					3: "Gyvoji gamta ir jos gėrybės: augalai, gyvūnai ir kt.",
					4: "Žmonių veikla kaimo ir miesto aplinkoje",
					5: "Pasaulio sampratos, jausenos, reiškinių,  daiktų ir jų savybių",
					7: "Lietuvos istorijos, kultūros, valstybingumo raiškos pavadinimai",
					6: "Vietovardžiai už Lietuvos ribų : tautos, miestai",
				},
				subclass: {
					11: "Nusipelniusių asmenybių – MOTERŲ vardai, pavardės",
					12: "Nusipelniusių asmenybių – VYRŲ vardai, pavardės",
					13: "Istorinės asmenybės (dažniausiai kunigaikščiai)",
					14: "Legendinės asmenybės (mokslo nepripažinti)",
					15: "Nusipelnę giminės",
					16: "Šventieji arba bažnyčios paskelbti šventaisiais",
					17: "Lietuvių, baltų DEIVĖS ir mitinės būtybės",
					18: "Lietuvių, baltų DIEVAI ir mitinės būtybės",
					19: "Lietuviški bendriniai vardai (vyriški ir moteriški)",
					21: "Vilniaus miesto dalių, istorinių priemiesčių (iki 1945 m) pavadinimai",
					22: "Kaimų pavadinimai",
					23: "Sodybų, dvarų pavadinimai",
					24: "Lietuvos miestelių ir gyvenviečių pavadinimai",
					25: "Lietuvos miestų pavadinimai",
					26: "Ežerų, pelkių pavadinimai (Lietuviškos kilmės hidronimai)",
					27: "Upių pavadinimai (Lietuviškos kilmės hidronimai)",
					28: "Kalvų, piliakalnių kitų geografinių objektų vietovardžiai",
					31: "Žoliniai augalai",
					32: "Gėlės",
					33: "Krūmai, vijokliai",
					34: "Medžiai, medynai",
					35: "Paukščiai",
					36: "Žvėrys, gyvuliai",
					37: "Žuvys, ropliai, vabzdžiai",
					38: "Miško gerybės",
					39: "Žmonių auginami produktai",
					41: "Žemdirbystės elementai ir aplinka",
					42: "Kaimo amatai, įranga, rezultatai",
					43: "Švietimo, aukljimo ir laisvalaikio formos",
					44: "Profesijos ir veiklų grupės",
					45: "Dvasinių mokymų sekėjai",
					46: "Institucijų pavadinimai",
					47: "Pramonės ir miesto statinių bei pastatų",
					48: "Statinių ir pastatų Lietuvos reljefe",
					51: "Fizinio pasaulio daiktų ir jų savybių",
					52: "Pasaulio dalių",
					53: "Metų laikų ir ir atmosferos reiškinių",
					54: "Gatvių savybių, savitumo",
					55: "Gamtinio reljiefo elementų",
					56: "Žvaigždynų, kosmoso ir aviacijos",
					57: "",
					58: "Gaminių ir įrangos",
					59: "Jausenos pojūčiai",
					61: "Tautų pavadinimai",
					62: "Valstybių pavadinimai",
					63: "Valstybių sostinės arba su Vilniumi draugaujantys miestai",
					65: "Pakeisti Mažosios Lietuvos vietovardžiai",
					66: "Lietuviškos kilmės vietovardžiai (be hidronimų) Lenkijoje, Baltarusijoje, Rusijoje, Ukrainoje",
					67: "Kiti ne Lietuvos geografinių objektų pavadinima",
					71: "Istorinės atminties ir visuomenės oganizacijos",
					72: "Lietuvos istorinių datų",
					73: "Entnografinių žemių ir Lietuvos kraštų",
					74: "Gynėjų, kovojančios Lietuvos",
					75: "Bendraujančios, dainuojančios Lietuvos savitumas",
					76: "Valstybingumo raidos ir požymių",
					77: "Senosios Lietuvos dvasingumo raiškos",
					78: "Krikščioniškosios Lietuvos raiškos",
					79: "Lietuvių pasakų ir legendų herojai ir elementai",
					64: "Istoriniai vietovardžiai už Lietuvos ribų, reikšmingi LDK miestai",
				},
			},
			maps: {
				group: {
					0: "Ortofotografiniai žemėlapiai",
					1: "LMA Vrublevskių bibliotekos žemėlapiai",
					2: "Istorinių topografijų rekonstrukcijos",
					3: "Topografiniai planai",
				},
			},
		},
	},
	en: {
		translation: {
			help: {
				first: {
					about: "About the project",
					usage: "User manual",
					contacts: "Contacts",
				},
				third: {
					info: "Information provided",
					how: "How to use?",
				},
			},
			sys: {
				review: "Review",
				swipe: "Sliding bar",
				windows: "Two windows",
				timeline: "Timeline",
				present: "The present",
				past: "Past",
				history: "History",
				search: "Search",
				visualize: "Imaging",
				visualizeBy: "Imaging by",
				objectType: "Object type",
				memoryType: "Type of remembrance",
				legend: "Legend",
				all: "All",
				objectClass: "Object class",
				objectSubclass: "Object sub-class",
				results: "Results",
				resultsNum: "Number of results",
				clearFilters: "Clear the filters",
				period: "Period of installation",
				extent: "Show in the list only the objects visible in the map coverage",
				shareUrl: "Link copied",
				basemapLight: "Bright map",
				basemapDark: "Dark map",
				basemapOrto: "Ortho-image",
				activity: "Activity",
				mode: "Mode",
				transparency: "Transparency",
				profession: "Profession",
				more: "More",
				collapse: "Retract",
				leftMap: "Left map",
				rightMap: "Right map",
				swipeText: "Scroll the bar to compare the two maps",
				dna: "DNA",
				year: "Year",
				notFound: "Not found",
				map: "Map",
				selectPersonMobile: "Select a specific personality from the list",
				selectPersonGraph:
					"Select a specific personality from the list on the left or use the interactive graph",
			},
			fields: {
				activityProfession: "Activity, profession",
				activityBrief: "Activity (creation) in brief",
				personConnection: "Connection of the person with Vilnius",
				awards: "Awards",
				objectName: "Name of the object",
				objectText: "Inscription on the object",
				objectDesc: "Object description",
				event: "Historical event",
				mapName: "Map name",
				mapDesc: "Map description",
				group: "Group",
				desc: "Description",
				type: "Type",
				scale: "Scale",
				coord: "Coordinates",
				made: "Drawn up",
				source: "Source",
				sources: "Sources",
				name: "Name",
				class: "Class",
				subclass: "Subclass",
				personRelated: "Related person",
				streetNames:
					"Names of the street or of its part (in original form), which are presented in historical maps",
				author: "Author",
				sourceDetailed: "Sources (author, name, website)",
				eventDate: "Date of the event",
				place: "Place",
				personalities: "Personalities",
				orgRelated: "Related organizations",
				streets: "Streets",
				burial: "Burial",
				family: "Family",
				researched: "Was interested in, researched or translated works",
				friends: "Friends, political associates and co-workers",
				ortoMaps: "Orthophotographic maps",
				libMaps: "Maps of the Wroblewski Library",
				topoHistoryMaps: "Reconstructions of historical topographies",
				topoMaps: "Topographic maps",
				sculptures: "Sculptures and monuments",
				plaques: "Memorial plaques",
				eventRelated: "Related events",
				personsRelated: "Related personalities",
				familyRelated: "Personality's relationship with family members",
				other: "Other",
				eventsTimeline: "Timeline of historical events",
				mapsRelated: "Related maps",
				plaquesRelated: "Related sculptures and memorial plaques",
				century: "c.",
				originalMaps: "Original maps used",
				nickname: "Nickname",
				photosRelated: "Related photos",
				streetLength: "Street length",
				currentStreet: "Current names of the street or part of a street",
				streetsRelated: "Related streets",
				sculptor: "Sculptor",
				architect: "Architect",
				additionalInfo: "Additional information",
        constructor: "Constructor",
			},
			home: {
				periods: "The city during the individual historical periods",
				plaques: "Sculptures and commemorative plaques",
				persons: "The personalities immortalised in the city",
				maps: "Historical plans and maps",
				streets: "Streets of the city",
				parts: "Parts of the city",
				foto: "Old photos and postcards",
				buildings: "Buildings",
				events: "Historical events",
				more: {
					periods:
						"The section presents 5 reconstructed historical topographies, which can be compared with each other or with the contemporary information. The topographies cover only the central part of Vilnius city",
					plaques:
						"The section contains information about all commemorative plaques in the city, monuments, sculptures and other artistic objects or objects of small architecture",
					persons:
						"The section contains information about the personalities for whom commemorative plaques, sculptures, monuments are installed in Vilnius city or streets are named after them",
					maps: "The section contains the historical plans, maps, ortho-photos and other cartographic material of Vilnius city",
					streets:
						"The section contains information about all streets of Vilnius city. It also presents information about the history of the streets of the central part of Vilnius city - the names of 5 historical periods and axial lines",
					parts:
						"The section contains information about the parts of Vilnius city - the elderships, sub-elderships, districts and the place names of different periods",
					foto: "The section contains photos and postcards of Vilnius city",
					buildings:
						"The section contains information about the buildings of Vilnius city, their year of construction, authors and founders",
					events:
						"The section contains information about the historical events, which are of the greatest importance for Vilnius city",
				},
				welcome1:
					"Open the virtual gates of Vilnius history and discover the capital by exploring historical maps, old photographs and the city's major events.",
				welcome2: "We invite you to explore and discover the DNA of Vilnius city!",
			},
			plaques: {
				objects: {
					1: "Commemorative or memorial plaque with text",
					2: "Sculpture, monument, sculptural composition",
					3: "Memorial plaque with bas-relief or other artistic elements",
					4: "Fresco, panel, mosaic, wall painting",
					5: "Name plate (name given to a building, structure or other object)",
					6: "Inscription, sign in the pavement, on the stairs, stones",
					7: "Place marking sign by means of small architecture",
					8: "Monument, tombstone in burial places",
				},

				memories: {
					1: "In memory of a famous person",
					2: "In memory of a group of famous people",
					3: "Abstract object",
					4: "In memory of an organisation",
					5: "In memory of a significant (historical) event",
					6: "To mark the burial place of a famous person",
					7: "Artistic symbol of an idea",
					8: "Marking of the place of a historic structure",
				},
				periods: {
					1: "Until 1795 (Partition of Poland)",
					2: "1796 - 1919 (Before the Polish occupation)",
					3: "1919 – 1939",
					4: "1939 – 1989 (Soviet era)",
					5: "Since 1990 (After the restoration of independence)",
				},
			},
			persons: {
				profession: {
					1: "Meritorious / honoured in struggle for Lithuania",
					2: "Artist, creator",
					3: "Scientist, research worker, explorer",
					4: "Technology developers; craftsmen/artisans/masters; engineers",
					5: "Organisers of education, public health, culture, social life",
					6: "Clergymen, missionaries, saints",
					7: "Leaders and politicians of the (democratic) State of Lithuania and organisers of its management",
					8: "Historical rulers, governors, lords of the manor/landlords of the State of Lithuania",
					9: "Craftsmen",
					10: "Rulers, governors, organisers of management, and politicians of other lands, countries, nations",
					11: "Other",
				},
				activity: {
					11: "Having sacrificed their lives in Lithuanian Freedom Struggles",
					12: "Lithuanian soldiers, partisans who have tragically died in an armed struggle (20th century)",
					13: "Political prisoners who have tragically died, who were tortured to death (20th century)",
					14: "Participants in the wars of independence, participants in the uprising who have tragically died",
					15: "Rebel fighters for the freedom of Lithuania, against the tsarist regime",
					16: "Educator, book-smuggler, publisher (until the 20th century)",
					17: "Participant in the armed struggle against the 20th century occupations",
					18: "Lithuanian people-exiles, political prisoners",
					19: "Figures of the Lithuanian Revival Movement (since 1987)",
					21: "Artist, painter, graphic artist, photographer",
					22: "Architect, sculptor, designer, puppeteer",
					23: "Instrumentalist musician, conductor, composer",
					24: "Vocalist musician, singer",
					25: "Theatre, cinema, opera, ballet actor, dancer",
					26: "Poet, translator of poetry",
					27: "Writer, playwright, journalist, publicist",
					28: "Actor, director",
					29: "Folk artist",
					31: "Humanities: linguist, musicologist, theatrologist, art researcher, semiotician, literary researcher",
					32: "Historical sciences: historian, local historian, archaeologist, ethnologist",
					33: "Educational science, psychology, Professors, Associate Professors",
					34: "Economist, sociologist, psychologist, lawyer, political scientist, management theorist (other social sciences:",
					35: "Biology, medicine. Ecologist, Biochemist, zoologist, botanist",
					36: "Physicist, chemist, geologist, geographer, geophysicist, astronomer, hydrologist",
					37: "Mathematics, Logic, Informatics",
					38: "Philosophy, Theology",
					39: "Other sciences",
					41: "Crop production and animal husbandry technologies: bioengineering, breeding: agronomists, veterinarians",
					42: "Food industry technologies, biotechnology",
					43: "Light industry technologies: production of wood, fabrics, other materials",
					44: "Mechanical engineering, machines, motor transport, aviation, constructors",
					45: "Energy, electrical, electronic engineering",
					46: "Computing equipment and technologies",
					47: "Materials engineering and technology",
					48: "Construction, construction technologies, construction of roads and bridges, environmental redevelopment engineering",
					49: "Economics",
					51: "Pedagogue, teacher, lecturer, educator",
					52: "Cultural worker",
					53: "Doctor, healthcare worker",
					54: "Savior of nations",
					56: "Athlete, sports organiser",
					57: "Traveler, climber, photographer, publicist, journalist",
					58: "Organiser of various businesses (tourism, old crafts, fishing, hunting)",
					59: "Civil servant",
					61: "Priests, saints",
					62: "Catholic priest, parson, pastor, missionary, monk",
					63: "Pope, Cardinal, Archbishop, Bishop, Archdeacon",
					64: "Evangelical (Lutheran and Protestant) clergyman",
					65: "Clergyman, monk of the Orthodox, Old Believers, Ukrainian Greek Catholic Church",
					66: "Judaism clergyman, rabbi",
					67: "Karaite clergyman",
					68: "Islamic clergyman",
					69: "Clergy of other religions",
					71: "Highest-level officials of the Republic of Lithuania (President, Speaker of the Seimas, Prime Minister)",
					72: "Heads of Lithuanian ministries and state institutions",
					73: "Vilnius city mayors, vice mayors, administration directors",
					74: "Servants of the Lithuanian state bodies",
					75: "Judges, lawyers, policemen, other law enforcement officers",
					76: "National defence officers, officers, generals",
					77: "Private soldiers of the national defence, partisans",
					78: "Lithuanian politicians, diplomats",
					79: "Business, production organiser, manager, supervisos, director",
					81: "Rules of the Grand Duchy of Lithuania (Grand Duke)",
					82: "Dukes, high nobles of the Grand Duchy of Lithuania (before the Union of Lublin)",
					83: "High nobles of the Grand Duchy of Lithuania, (after the Union of Lublin), hetmans, military commanders",
					84: "Other nobles, lords of the manor/landlords of the Grand Duchy of Lithuania",
					85: "Vicegerents, placed officials of the Russian Empire",
					86: "Placed officials of other occupation regimes",
					87: "",
					88: "",
					91: "Restorers of buildings, interior, furniture, art, etc.",
					92: "Masters of metal casting (bells, cannons)",
					93: "Blacksmiths, wheelwrights, gunsmiths",
					94: "Cabinetmakers, carpenters, glaziers",
					95: "Tailors, hairdressers, shoemakers",
					96: "Goldsmiths",
					97: "Herbalists, old pharmacists",
					98: "Tribal leaders (non-Lithuanian)",
					99: "Highest-level officials of foreign countries (President, Speaker of the Seimas, Prime Minister)",
					100: "Heads of foreign ministries and state institutions",
					101: "Foresters, forestry officers, gardeners, florists",
					102: "Fishery workers",
					103: "Beekeepers",
					104: "Politicians, diplomats of foreign countries",
					105: "Military engineering",
					106: "Printers",
					107: "National defence officials, officers, generals of foreign countries",
					111: "Other",
				},
			},
			streets: {
				class: {
					1: "Personal names",
					2: "Place names of Vilnius and Lithuania",
					3: "Wildlife and its products: plants, animals, etc.",
					4: "Human activities in rural and urban environment",
					5: "Concept of the world, feeling, phenomena, objects and their properties",
					7: "Names of expression of Lithuanian history, culture, statehood",
					6: "Place names outside Lithuania: nations, cities",
				},
				subclass: {
					11: "Forenames, surnames of honourable personalities FEMALE",
					12: "Forenames, surnames of honourable personalities MALE",
					13: "Historical figures (usually dukes)",
					14: "Legendary personalities (not recognized by science)",
					15: "Honourable families",
					16: "Saints or persons canonized as saints by the church",
					17: "Lithuanian, Baltic GODDESSES and mythical creatures",
					18: "Lithuanian, Baltic GODS and mythical creatures",
					19: "Lithuanian common names (male and female)",
					21: "Names of parts, of historical suburbs (before 1945) of Vilnius city",
					22: "Names of villages",
					23: "Names of homesteads, manors",
					24: "Names of Lithuanian towns and settlements",
					25: "Names of Lithuanian cities",
					26: "Names of lakes, marshes (hydronyms of Lithuanian origin)",
					27: "Names of rivers (hydronyms of Lithuanian origin)",
					28: "Place names of hills, mounds and other geographical objects",
					31: "Herbaceous plants",
					32: "Flowers",
					33: "Bushes, vines",
					34: "Trees, stands",
					35: "Birds",
					36: "Wild animals, animals",
					37: "Fish, reptiles, insects",
					38: "Forest products",
					39: "Products grown by people",
					41: "Elements of agriculture and the environment",
					42: "Village crafts, equipment, results",
					43: "Forms of education, upbringing and leisure",
					44: "Professions and activity groups",
					45: "Followers of spiritual education",
					46: "Names of institutions",
					47: "Industrial and urban structures and buildings",
					48: "Structures and buildings in the terrain of Lithuania",
					51: "Objects of the physical world and their properties",
					52: "Parts of the world",
					53: "Seasons and atmospheric phenomena",
					54: "Street characteristics, singularity",
					55: "Elements of natural relief",
					56: "Constellations, space and aviation",
					57: "",
					58: "Products and equipment",
					59: "Feeling, sensation",
					61: "Names of nations",
					62: "Names of states",
					63: "Capitals of states or friend-cities of Vilnius",
					65: "Changed place names of Lithuania Minor",
					66: "Place names of Lithuanian origin (excluding hydronyms) in Poland, Belarus, Russia, Ukraine",
					67: "Other names of non-Lithuanian geographical objects",
					71: "Organisations of historical memory and public organisations",
					72: "Lithuanian historical dates",
					73: "Ethnographic lands and regions of Lithuania",
					74: "Defenders, struggling Lithuania",
					75: "Singularity of sociable, singing Lithuania",
					76: "Development and signs of statehood",
					77: "Manifestations of the spirituality of old Lithuania",
					78: "Manifestations of Christian Lithuania",
					79: "Heroes and elements of Lithuanian fairy tales and legends",
					64: "Historical place names outside Lithuania, significant cities of the Grand Duchy of Lithuania",
				},
			},
			maps: {
				group: {
					0: "Orthophotographic maps",
					1: "Maps of the Wroblewski Library ",
					2: "Vilnius DNA maps",
					3: "Large scale maps",
				},
			},
		},
	},
	ru: {
		translation: {
			help: {
				first: {
					about: "О проекте",
					usage: "Руководство пользователя",
					contacts: "Контакты",
				},
				third: {
					info: "Предоставляемая информация",
					how: "Как пользоваться?",
				},
			},
			sys: {
				review: "Обзор",
				swipe: "Полоса прокрутки",
				windows: "Два окна",
				timeline: "Временная лента",
				present: "Настоящее",
				past: "Прошлое",
				history: "История",
				search: "Поиск",
				visualize: "Визуализация",
				visualizeBy: "Представлять по",
				objectType: "типу объекта",
				memoryType: "типу памяти",
				legend: "Условные знаки",
				all: "Все",
				objectClass: "Класс объекта",
				objectSubclass: "Подкласс объекта",
				results: "Результаты",
				resultsNum: "Количество результатов",
				clearFilters: "Очистить фильтры",
				period: "Период установки",
				extent: "Показывать в списке только объекты, видимые в охвате карты",
				shareUrl: "Ссылка скопирована",
				basemapLight: "Светлая карта",
				basemapDark: "Темная карта",
				basemapOrto: "Ортофото",
				activity: "Деятельность",
				mode: "Режим",
				transparency: "Прозрачность",
				profession: "Профессия",
				more: "Подробнее",
				collapse: "Свернуть",
				leftMap: "Левая карта",
				rightMap: "Правая карта",
				swipeText: "Крутите полосу вниз и сравнивайте карты",
				dna: "ДНК",
				year: "Год",
				notFound: "Не найдено",
				map: "Карта",
				selectPersonMobile: "Выберите конкретную личность из списка",
				selectPersonGraph:
					"Выберите конкретную личность из списка слева или воспользуйтесь интерактивным графиком",
			},
			fields: {
				activityProfession: "Деятельность-профессия",
				activityBrief: "Коротко о деятельности (творчестве)",
				personConnection: "Связь лица с Вильнюсом",
				awards: "Награды",
				objectName: "Название объекта",
				objectText: "Надпись на объекте",
				objectDesc: "Описание объекта",
				event: "Историческое событие",
				mapName: "Название карты",
				mapDesc: "Описание карты",
				group: "Группа",
				desc: "Описание",
				type: "Тип",
				scale: "Масштаб",
				coord: "Координаты",
				made: "Сформирован",
				source: "Источник",
				sources: "Источники",
				name: "Заголовок",
				class: "Класс",
				subclass: "Подкласс",
				personRelated: "Касающееся лицо",
				streetNames: "На исторических картах указаны названия улицы или ее части (исходная форма)",
				author: "Автор",
				sourceDetailed: "Источники (Автор, название, внутр. страница)",
				eventDate: "Дата события",
				place: "Место",
				personalities: "Личности",
				orgRelated: "Касающиеся организации",
				streets: "Улицы",
				burial: "Погребение",
				family: "Семья",
				researched: "Интересовался (-ась), исследовал (-а) или переводил (-а) творчество",
				friends: "Друзья, политические соратники и коллеги по работе",
				ortoMaps: "Ортофотографические карты",
				libMaps: "Карты библиотеки Врублевских",
				topoHistoryMaps: "Реконструкции исторической топографии",
				topoMaps: "Топографические карты",
				sculptures: "Скульптуры и памятники",
				plaques: "Мемориальные доски",
				eventRelated: "Связанные события",
				personsRelated: "Связанные личности",
				familyRelated: "Отношения личности с членами семьи",
				other: "Другие",
				eventsTimeline: "Лента исторических событий",
				mapsRelated: "Связанные карты",
				plaquesRelated: "Связанные скульптуры и мемориальные доски",
				century: "в.",
				originalMaps: "Использованные оригинальные карты",
				nickname: "Псевдоним",
				photosRelated: "Связанные фотографии",
				streetLength: "Длина улицы",
				currentStreet: "Современные названия улицы или ее части",
				streetsRelated: "Связанные улицы",
				sculptor: "Скульптор",
				architect: "Архитектор",
				additionalInfo: "Дополнительная информация",
        constructor: "Конструктор",
			},
			home: {
				periods: "Город в отдельные исторические периоды",
				plaques: "Скульптуры и памятные доски",
				persons: "Личности, увековеченные в городе",
				maps: "Исторические планы и карты",
				streets: "Улицы города",
				parts: "Части города",
				foto: "Старые фотографии и открытки",
				buildings: "Здания",
				events: "Исторические события",
				more: {
					periods:
						"В разделе представлены 5 реконструированных исторических топографий, которые можно сравнить друг с другом или с современными данными. Топографии охватывают только центральную часть города Вильнюса",
					plaques:
						"Раздел содержит информацию обо всех памятных досках, памятниках, скульптурах и других художественных объектах или малых архитектурных объектах в городе (как города Вильнюса, так и установленных по частной инициативе)",
					persons:
						"Раздел содержит информацию о личностях, в честь которых в городе Вильнюсе установлены памятные доски, скульптуры, памятники или названы улицы",
					maps: "Раздел содержит исторические планы города Вильнюса, карты ортофотографии и другой картографический материал",
					streets:
						"Раздел содержит информацию обо всех улицах города Вильнюса, а также информацию об истории улиц центральной части города Вильнюса - названия 5-и исторических периодов и осевые линии",
					parts:
						"Раздел содержит информацию о частях города Вильнюса - участках старост и старейшин, районах и исторических географических названиях разных эпох",
					foto: "Раздел содержит фотографии и открытки города Вильнюса",
					buildings:
						"Раздел содержит информацию о городских постройках Вильнюса, годе их строительства, об авторах и основателях",
					events: "Раздел содержит информацию о наиболее важных для города Вильнюса исторических событиях",
				},
				welcome1: `Поздравляем с посещением сайта «ДНК Вильнюса». Здесь вы найдете исторические карты города и средства для их изучения в различных разрезах.`,
				welcome2: `Приглашаем вас исследовать Вильнюс!`,
			},
			plaques: {
				objects: {
					1: "Памятная или мемориальная доска с текстом",
					2: "Скульптура, памятник, скульптурная композиция",
					3: "Мемориальная доска с барельефом или другими художественными элементами",
					4: "Фреска, панно, мозаика, роспись стен",
					5: "Табличка (название, присвоенное зданию, сооружению или другому объекту)",
					6: "Надпись, отметка на тротуаре, лестнице, камнях",
					7: "Знак идентификации местоположения средствами малой архитектуры",
					8: "Памятник, надгробие в местах захоронения",
				},
				memories: {
					1: "В память об известном человеке",
					2: "В память о группе выдающихся личностей",
					3: "Абстрактный объект",
					4: "В память об организации",
					5: "В память о знаменательном (историческом) событии",
					6: "Для обозначения места захоронения известной личности",
					7: "Художественный символ идеи",
					8: "Обозначение местонахождения исторической постройки",
				},
				periods: {
					1: "До 1795 г. (раздела Республики)",
					2: "1796 - 1919 гг. (до польской оккупации)",
					3: "1919 - 1939 гг.",
					4: "1939 - 1989 гг. (в советский период)",
					5: "с 1990 г. (после восстановления независимости)",
				},
			},
			persons: {
				profession: {
					1: "Отличившиеся  в борьбе за Литву",
					2: "Художник, создатель",
					3: "Ученый, научный работник, исследователь (без технологии)",
					4: "Разработчики технологий, мастера, инженеры",
					5: "Организаторы образования, здравоохранения, культуры, общественной жизни",
					6: "Священнослужители, миссионеры, святые",
					7: "Руководители, политики Литовского (демократического) государства, организаторы управления",
					8: "Исторические правители, наместники, помещики Литовского государства",
					9: "Ремесленники",
					10: "Правители, наместники, организаторы управления, политики других земель, стран, народов",
					11: "Прочие",
				},
				activity: {
					11: "Пожертвовавшие жизнью в борьбе за свободу Литвы",
					12: "Литовские солдаты, партизаны, погибшие в вооруженной борьбе (ХХ в.)",
					13: "Погибшие, замученные политические заключенные (ХХ в.)",
					14: "Погибшие участники восстаний и войн за независимость",
					15: "Повстанцы, борцы за свободу Литвы, против царского режима",
					16: "Просветитель, книгоноша, издатель (до ХХ в.)",
					17: "Участник вооруженной борьбы против оккупаций ХХ в.",
					18: "Ссыльные, политзаключенные из Литвы",
					19: "Активисты движения за возрождение Литвы (с 1987 г.)",
					21: "Художник, живописец, график, фотограф",
					22: "Архитектор, скульптор, дизайнер, кукольник",
					23: "Музыкант-инструменталист, дирижер, композитор",
					24: "Музыкант-вокалист, певец",
					25: "Артист, танцор театра, кино, оперы, балета",
					26: "Поэт, переводчик поэзии",
					27: "Писатель, драматург, журналист, публицист",
					28: "Актер, режиссер",
					29: "Народный артист",
					31: "Гуманитарные науки: лингвист, музыковед, драматург, искусствовед, семиотик, литературовед",
					32: "Исторические науки: историк, краевед, археолог, этнолог",
					33: "Эдукология, психология, профессора, доценты",
					34: "Экономист, социолог, психолог, юрист, политолог, теоретик менеджмента (другие социальные науки:",
					35: "Биология, медицина. Эколог, биохимик, зоолог, ботаник",
					36: "Физик, химик, геолог, географ, геофизик, астроном, гидролог",
					37: "Математика, Логика, Информатика",
					38: "Философия, богословие",
					39: "Другие науки",
					41: "Технологии растениеводства и животноводства: биоинженерия, селекция: агрономы, ветеринары",
					42: "Технологии, биотехнологии пищевой промышленности",
					43: "Технологии легкой промышленности: производство древесины, тканей, других материалов",
					44: "Машиностроение, машины, автотранспорт, авиация, конструкторы",
					45: "Энергетика, электротехника, электроника",
					46: "Вычислительная техника и технологии",
					47: "Материаловедение и технологии",
					48: "Строительство, технологии строительства дорог, мостов, строительство, природоохранная инженерия",
					49: "Экономика",
					51: "Педагог, учитель, преподаватель, воспитатель",
					52: "Работник культуры",
					53: "Врач, работник здравоохранения",
					54: "Спаситель народов",
					56: "Спортсмен, спортивный организатор",
					57: "Путешественник, альпинист, фотограф, публицист, журналист",
					58: "Организатор различных видов бизнеса (туризма, старинных ремесел, рыболовного промысла, охоты)",
					59: "Госслужащий",
					61: "Жрецы, святые",
					62: "Католический священник, настоятель, пастор, миссионер, монах",
					63: "Папа, кардинал, архиепископ, епископ, архидиакон",
					64: "Евангелический (лютеранский и протестантский) священнослужитель",
					65: "Священнослужитель православных, старообрядцев, юнитов, монах",
					66: "Еврейский священнослужитель, раввин",
					67: "Караимский священнослужитель",
					68: "Исламский священнослужитель",
					69: "Духовенство других религий",
					71: "Высшие должностные лица Литовской Республики (Президент, Председатель Сейма, Премьер-министр)",
					72: "Главы литовских министерств и государственных учреждений",
					73: "Мэры, вице-мэры города Вильнюса, директора адм.",
					74: "Сотрудники государственного аппарата Литвы",
					75: "Судьи, адвокаты, сотрудники полиции и другие сотрудники правоохранительных органов",
					76: "Должностные лица национальной обороны, офицеры, генералы",
					77: "Рядовые солдаты национальной обороны, партизаны",
					78: "Литовские политики, дипломаты",
					79: "Организатор бизнеса, производства, управляющий, менеджер, директор",
					81: "Правитель ВКЛ (Великий князь)",
					82: "Князья, высшие дворяне ВКЛ (до Унии)",
					83: "Высшие дворяне ВКЛ (после Унии), гетманы, военачальники",
					84: "Другие дворяне, помещики ВКЛ",
					85: "Чиновники Российской империи",
					86: "Чиновники-ставленники других оккупационных режимов",
					87: "",
					88: "",
					91: "Реставраторы зданий, интерьеров, мебели, произведений искусства и др.",
					92: "Мастера литья металла (колоколов, пушек)",
					93: "Кузнецы, колесники, оружейники",
					94: "Мебельщики, столяры, стекольщики",
					95: "Портные, цирюльники, сапожники",
					96: "Ювелиры",
					97: "Травники, старые аптекари",
					98: "Вожди племен (не литовских)",
					99: "Высшие должностные лица иностранных государств (Президент, Председатель Сейма, Премьер-министр)",
					100: "Главы внешнеполитических ведомств и государственных учреждений",
					101: "Лесники, лесничие, садовники, цветоводы",
					102: "Рыбопромышленники",
					103: "Пчеловоды",
					104: "Политики и дипломаты зарубежных стран",
					105: "Военная инженерия",
					106: "Печатники",
					107: "Должностные лица, офицеры, генералы национальной обороны иностранных государств",
					111: "Прочие",
				},
			},
			streets: {
				class: {
					1: "Имена собственные",
					2: "Географические названия Вильнюса и Литвы",
					3: "Живая природа и ее блага: растения, животные и др.",
					4: "Деятельность человека в сельской и городской среде",
					5: "Мировое понятие чувств, явлений, предметов и их свойств",
					7: "Наименования литовской истории, культуры, выражения государственности",
					6: "Географические названия за пределами Литвы: нации, города",
				},
				subclass: {
					11: "Имена и фамилии заслуженных личностей - ЖЕНЩИН",
					12: "Имена и фамилии заслуженных личностей - МУЖЧИН",
					13: "Исторические личности (обычно князья)",
					14: "Легендарные личности (непризнанные наукой)",
					15: "Заслуженный род",
					16: "Святые или канонизированные церковью",
					17: "БОГИНИ и мифические существа литовцев, балтов",
					18: "БОГИ и мифические существа литовцев, балтов",
					19: "Литовские имена общие (мужские и женские)",
					21: "Названия частей города Вильнюса, исторических пригородов (до 1945 г.)",
					22: "Названия деревень",
					23: "Названия усадеб, поместий",
					24: "Названия литовских поселков и сел",
					25: "Названия литовских городов",
					26: "Названия озер, болот (гидронимы литовского происхождения)",
					27: "Названия рек (гидронимы литовского происхождения)",
					28: "Географические названия холмов, курганов и других географических объектов",
					31: "Травянистые растения",
					32: "Цветы",
					33: "Кустарники, вьюнки",
					34: "Деревья, рощи",
					35: "Птицы",
					36: "Звери, животные",
					37: "Рыбы, рептилии, насекомые",
					38: "Дары леса",
					39: "Продукты, выращиваемые людьми",
					41: "Элементы и среда земледелия",
					42: "Деревенские ремесла, оборудование, результаты",
					43: "Формы просвещения, воспитания и досуга",
					44: "Профессии и группы деятельности",
					45: "Последователи духовного учения",
					46: "Названия учреждений",
					47: "Промышленных и городских сооружений и зданий",
					48: "Сооружений и построек на рельефе Литвы",
					51: "Объектов физического мира и их свойств",
					52: "Частей света",
					53: "Времен года и атмосферных явлений",
					54: "Особенностей и уникальности улиц",
					55: "Элементов природного рельефа",
					56: "Созвездий, космоса и авиации",
					57: "",
					58: "Продукции и оборудования",
					59: "Явлений",
					61: "Названия наций",
					62: "Названия государств",
					63: "Столицы государств или города-побратимы Вильнюса",
					65: "Измененные географические названия Малой Литвы",
					66: "Географические названия литовского происхождения (без гидронимов) в Польше, Белоруссии, России, на Украине",
					67: "Другие названия географических объектов за пределами Литвы",
					71: "Организации исторической памяти и общественные организации",
					72: "Литовских исторических дат",
					73: "Этнографических земель и литовских регионов",
					74: "Защитников, борющейся Литвы",
					75: "Уникальность общительной, поющей Литвы",
					76: "Развития и признаков государственности",
					77: "Проявление духовности Старой Литвы",
					78: "Проявление христианской Литвы",
					79: "Герои и элементы литовских сказок и легенд",
					64: "Исторические географические названия за пределами Литвы, города, значимые для ВКЛ",
				},
			},
			maps: {
				group: {
					0: "Ортофотографические карты",
					1: "Карты библиотеки Врублевских",
					2: "Карты ДНК Вильнюса",
					3: "Крупномасштабные карты",
				},
			},
		},
	},
	pl: {
		translation: {
			help: {
				first: {
					about: "O projekcie",
					usage: "Instrukcja użytkowania",
					contacts: "Kontakty",
				},
				third: {
					info: "Podane informacje",
					how: "Jak używać?",
				},
			},
			sys: {
				review: "Wyświetlenie",
				swipe: "Pasek przewijania",
				windows: "Dwa okna",
				timeline: "Oś czasu",
				present: "Teraźniejszość",
				past: "Przeszłość",
				history: "Historia",
				search: "Szukać",
				visualize: "Przedstawienie",
				visualizeBy: "Przedstawić według",
				objectType: "Rodzaj obiektu",
				memoryType: "Typ pamięci",
				legend: "Znaki umówne",
				all: "Wszystko",
				objectClass: "Klasa obiektu",
				objectSubclass: "Podklasa obiektu",
				results: "Wyniki",
				resultsNum: "Liczba wyników",
				clearFilters: "Wyczyść filtry",
				period: "Okres instalacji",
				extent: "Pokazać na liście tylko obiekty widoczne w pokryciu mapy",
				shareUrl: "Link skopiowany",
				basemapLight: "Jasna mapa",
				basemapDark: "Ciemna mapa",
				basemapOrto: "Ortofotofotomapa",
				activity: "Działalność",
				mode: "Zawód",
				transparency: "Tryb",
				profession: "Przezroczystość",
				more: "Więcej",
				collapse: "Zwiń",
				leftMap: "Lewa mapa",
				rightMap: "Prawa mapa",
				swipeText: "Przewiń pasek i porównaj obie mapy",
				dna: "DNA",
				year: "Rok",
				notFound: "Nie znaleziono",
				map: "Mapa",
				selectPersonMobile: "Wybierz konkretną osobowość z listy",
				selectPersonGraph:
					"Wybierz konkretną osobowość z listy po lewej stronie lub skorzystaj z interaktywnego wykresu",
			},
			fields: {
				activityProfession: "Działalność zawód",
				activityBrief: "Działanie (twórczość) w skrócie",
				personConnection: "Osobisty związek z Wilnem",
				awards: "Nagrody",
				objectName: "Nazwa obiektu",
				objectText: "Napis na obiekcie",
				objectDesc: "Opis obiektu",
				event: "Historyczne wydarzenie",
				mapName: "Nazwa mapy",
				mapDesc: "Opis mapy",
				group: "Grupa",
				desc: "Opis",
				type: "Typ",
				scale: "Skala",
				coord: "Współrzędne",
				made: "Skompilowano",
				source: "Źródło",
				sources: "Źródła",
				name: "Tytuł",
				class: "Klasa",
				subclass: "Podklasa",
				personRelated: "Powiązana osoba",
				streetNames: "Mapy historyczne przedstawiają nazwy ulicy lub jej części (forma oryginalna)",
				author: "Autor",
				sourceDetailed: "Źródła (autor, tytuł, strona int.)",
				eventDate: "Data wydarzenia",
				place: "Miejsce",
				personalities: "Osobowości",
				orgRelated: "Powiązane organizacje",
				streets: "Ulice",
				burial: "Pogrzeb",
				family: "Rodzina",
				researched: "Interesował się, badał lub tłumaczył twórczość",
				friends: "Przyjaciele, towarzysze polityczni i współpracownicy",
				ortoMaps: "Mapy ortofotograficzne",
				libMaps: "Mapy Biblioteki im. Wróblewskich",
				topoHistoryMaps: "Rekonstrukcje topografii historycznych",
				topoMaps: "Mapy topograficzne",
				sculptures: "Rzeźby i pomniki",
				plaques: "Tablice memorialne",
				eventRelated: "Powiązane wydarzenia",
				personsRelated: "Powiązane osobowości",
				familyRelated: "Relacje osobowości z członkami rodziny",
				other: "Inni",
				eventsTimeline: "Linia wydarzeń historycznych",
				mapsRelated: "Powiązane mapy",
				plaquesRelated: "Powiązane rzeźby i tablice memorialne",
				century: "w.",
				originalMaps: "Użyte oryginalne mapy",
				nickname: "Pseudonim",
				photosRelated: "Powiązane zdjęcia",
				streetLength: "Długość ulicy",
				currentStreet: "Aktualne nazwy ulicy lub jej części",
				streetsRelated: "Powiązane ulicy",
				sculptor: "Rzeźbiarz",
				architect: "Architekt",
				additionalInfo: "Dodatkowe informacje",
        constructor: "Konstruktor",
			},
			home: {
				periods: "Miasto w poszczególnych okresach historycznych",
				plaques: "Rzeźby i tablice pamiątkowe",
				persons: "Osobowości uwiecznione w mieście",
				maps: "Plany i mapy historyczne",
				streets: "Ulice miasta",
				parts: "Części miasta",
				foto: "Stare fotografie i pocztówki",
				buildings: "Budynki",
				events: "Wydarzenia historyczne",
				more: {
					periods:
						"W dziale przedstawiono 5 zrekonstruowanych topografii historycznych, które można porównać ze sobą lub ze współczesnymi informacjami. Topografie obejmują tylko centralną część Wilna",
					plaques:
						"Dział zawiera informacje o wszystkich tablicach pamiątkowych, pomnikach, rzeźbach i innych obiektach artystycznych lub obiektach małej architektury w mieście (zarówno tych należących do miasta Wilna, jak i wzniesionych z inicjatyw prywatnych)",
					persons:
						"Dział zawiera informacje o osobistościach, dla których w Wilnie umieszczono tablice pamiątkowe, rzeźby, pomniki lub ulice nazwane ich imieniem",
					maps: "Dział zawiera plany historyczne miasta Wilna, mapy, ortofotografie i inny materiał kartograficzny",
					streets:
						"Dział zawiera informacje o wszystkich ulicach miasta Wilna wraz z informacjami o historii ulic centralnej części miasta Wilna - nazwy 5 okresów historycznych i linii osiowych",
					parts:
						"Dział zawiera informacje o częściach miasta Wilna – starostwach, małych starostwach, dzielnicach i historycznych nazwach miejscowości z różnych epok",
					foto: "Dział zawiera fotografie i pocztówki miasta Wilna",
					buildings:
						"Dział zawiera informacje o budynkach miasta Wilna, roku ich powstania, autorach i fundatorach",
					events: "Dział zawiera informacje o najważniejszych wydarzeniach historycznych dla miasta Wilna",
				},
				welcome1:
					"Gratulujemy odwiedzenia strony „DNR Wilna”. Tutaj znajdziesz historyczne mapy miasta i narzędzia do ich badania w różnych działach.",
				welcome2: "Zapraszamy do zwiedzania Wilna!",
			},
			plaques: {
				objects: {
					1: "Tablica pamiątkowa lub memorialna z tekstem",
					2: "Rzeźba, pomnik, kompozycja rzeźbiarska",
					3: "Tablica pamiątkowa z płaskorzeźbą lub innymi elementami artystycznymi",
					4: "Fresk, panel, mozaika, malarstwo ścienne",
					5: "Tabliczka znamionowa (nazwa nadana budynkowi, konstrukcji lub innemu obiektowi)",
					6: "Napis, znak w bruku, na schodach, kamienie",
					7: "Symbol zaznaczenia miejsca za pomocą środków małej architektury",
					8: "Pomnik, nagrobek w miejscach pochówku",
				},
				memories: {
					1: "Ku pamięci wybitnej osoby",
					2: "Pamięci grupy wybitnych osób",
					3: "Obiekt abstrakcyjny",
					4: "Ku pamięci organizacji",
					5: "Ku pamięci ważnego (historycznego) wydarzenia",
					6: "W celu zaznaczenia miejsca pochówku znanej osoby",
					7: "Artystyczny symbol idei",
					8: "Zaznaczenie historycznego miejsca budowy",
				},
				periods: {
					1: "Do 1795 roku (Podziału Republiki)",
					2: "W latach 1796 - 1919 (Przed polską okupacją)",
					3: "W latach 1919 - 1939",
					4: "W latach 1939 - 1989 (Era radziecka)",
					5: "od 1990 roku (Po uzyskaniu niepodległości)",
				},
			},
			persons: {
				profession: {
					1: "Zasłużony w walce o Litwę",
					2: "Artysta, twórca",
					3: "Naukowiec, pracownik naukowy, badacz (bez technologii)",
					4: "Twórcy technologii, majstrowie, inżynierowie",
					5: "Organizatorzy edukacji, zdrowia publicznego, kultury, życia społecznego",
					6: "Duchowni, misjonarze, święci",
					7: "Liderzy, politycy, organizatorzy zarządzania (demokratycznego) państwem litewskim",
					8: "Historyczni władcy, namiestnicy, właściciele ziemscy państwa litewskiego",
					9: "Rzemieślnicy",
					10: "Władcy, namiestnicy, organizatorzy zarządzania, politycy innych krajów, państw, narodów",
					11: "Inni",
				},
				activity: {
					11: "Poświęcili życie w walce o wolność Litwy",
					12: "Żołnierze litewscy, partyzanci zabici w walce zbrojnej (XX w.)",
					13: "Więźniowie polityczni, którzy byli torturowani i zginęli (XX w.)",
					14: "Uczestnicy wojen niepodległościowych i powstania, którzy zginęli",
					15: "Powstańcy, bojownicy o wolność Litwy, przeciwko reżimowi carskiemu",
					16: "Oświecający, kolportarz książek, wydawca (do XX wieku)",
					17: "Uczestnik walki zbrojnej z XX okupacjami",
					18: "Litwini wygnańcy, więźniowie polityczni",
					19: "Bohaterowie litewskiego ruchu odrodzenia (od 1987 r.)",
					21: "Artysta, malarz, grafik, fotograf",
					22: "Architekt, rzeźbiarz, projektant, lalkarz",
					23: "Muzyk, instrumentalista, dyrygent, kompozytor",
					24: "Muzyk, wokalista, śpiewak",
					25: "Aktor teatralny, filmowy, operowy, baletowy, tancerz",
					26: "Poeta, tłumacz poezji",
					27: "Pisarz, dramaturg, dziennikarz, publicysta",
					28: "Aktor, reżyser",
					29: "Artysta ludowy",
					31: "Humanistyka: Językoznawca, muzykolog, dramaturg, krytyk sztuki, semiotyk, literaturoznawca",
					32: "Nauki historyczne: Historyk, miejscowy historyk, archeolog, etnolog",
					33: "Edukologia, psychologia, profesorowie, docentowie",
					34: "Ekonomista, socjolog, psycholog, prawnik, politolog, teoretyk zarządzania (inne nauki społeczne:",
					35: "Biologia, medycyna. Ekolog, biochemik, zoolog, botanik",
					36: "Fizyk, chemik, geolog, geograf, geofizyk, astronom, hydrolog",
					37: "Matematyka, logika, informatyka",
					38: "Filozofia, teologia",
					39: "Inne nauki",
					41: "Technologie hodowli roślin i zwierząt: bioinżynieria, hodowla: agronomowie, lekarze weterynarii",
					42: "Technologie przemysłu spożywczego, biotechnologia",
					43: "Technologie przemysłu lekkiego: produkcja drewna, tkanin, innych materiałów",
					44: "Budowa maszyn, maszyny, transport samochodowy, lotnictwo, konstruktorzy",
					45: "Energetyka, elektrotechnika, elektronika",
					46: "Technika i technologie komputerowe",
					47: "Inżynieria i technologia materiałowa",
					48: "Budownictwo, technologie budowy dróg, mostów, budownictwo, inżynieria przebudowy środowiska",
					49: "Ekonomia",
					51: "Pedagog, nauczyciel, wykładowca, wychowawca",
					52: "Pracownik kultury",
					53: "Lekarz, pracownik służby zdrowia",
					54: "Zbawiciel narodów",
					56: "Sportowiec, organizator sportu",
					57: "Podróżnik, himalaista, fotograf, publicysta, dziennikarz",
					58: "Organizator różnych biznesów (turystyka, dawne rzemiosło, wędkarstwo, łowiectwo)",
					59: "Służba cywilna",
					61: "Kapłani, święci",
					62: "Ksiądz katolicki, ksiądz, proboszcz, misjonarz, mnich",
					63: "Papież, kardynał, arcybiskup, biskup, archidiakon",
					64: "Duchowny ewangelicki (luterański i protestancki)",
					65: "Duchowny prawosławnych, staroobrzędowców, duchowny unicki, mnich",
					66: "Duchowny żydowski, rabin",
					67: "Duchowny karaimski",
					68: "Duchowny islamski",
					69: "Duchowni innych wyznań",
					71: "Najwyżsi urzędnicy Republiki Litewskiej (Prezydent, Marszałek Sejmu, Premier)",
					72: "Szefowie litewskich ministerstw i instytucji państwowych",
					73: "Burmistrzowie miasta Wilna, wiceburmistrzowie, adm. dyrektorzy",
					74: "Pracownicy litewskiego aparatu państwowego",
					75: "Sędziowie, prawnicy, policjanci i inni funkcjonariusze organów ścigania",
					76: "Oficerowie obrony narodowej, wojskowi, generałowie",
					77: "Regularni żołnierze Gwardii Narodowej, partyzanci",
					78: "Litewscy politycy, dyplomaci",
					79: "Organizator przedsiębiorstwa, produkcji, manager, kierownik, dyrektor",
					81: "Władca WKL (Wielki Książę)",
					82: "Książęta, wysocy szlachcice WKL (przed Unią)",
					83: "Wysoka szlachta WKL, (po Unii) hetmoni, watażkowie",
					84: "Inni szlachcice WKL, właściciele ziemscy",
					85: "Zastępcy urzędników Imperium Rosyjskiego, urzędnicy państwowi",
					86: "Urzędnicy państwowi innych reżimów okupacyjnych",
					87: "",
					88: "",
					91: "Konserwatorzy budynków, wnętrz, mebli, dzieł sztuki itp.",
					92: "Mistrzowie odlewania metali (dzwony, armaty)",
					93: "Kowale, kołodzieje, rusznikarze",
					94: "Meblarze, stolarze, szklarze",
					95: "Krawcy, fryzjerzy, szewcy",
					96: "Złotnicy,",
					97: "Zielarze, starzy farmaceuci",
					98: "Przywódcy plemienni (nie litewscy)",
					99: "Najwyżsi urzędnicy obcych krajów (Prezydent, Marszałek Sejmu, Premier)",
					100: "Szefowie ministerstw spraw zagranicznych i instytucji państwowych",
					101: "Leśnicy, ogrodnicy, floryści",
					102: "Rybacy",
					103: "Pszczelarze",
					104: "Politycy i dyplomaci obcych krajów",
					105: "Inżynieria wojskowa",
					106: "Drukarze",
					107: "Oficerowie obrony narodowej, wojskowi, generałowie obcych krajów",
					111: "Inni",
				},
			},
			streets: {
				class: {
					1: "Nazwy osobiste",
					2: "Nazwy miejscowości Wilna i Litwy",
					3: "Żywa przyroda i jej bogactwa: rośliny, zwierzęta itp.",
					4: "Działalność człowieka w środowisku wiejskim i miejskim",
					5: "Pojęcia świata, uczucia, zjawiska, przedmioty i ich właściwości",
					7: "Nazwy przejawy historii, kultury, państwowości Litwy",
					6: "Nazwy miejscowości poza Litwą: narody, miasta",
				},
				subclass: {
					11: "Imiona i nazwiska zasłużonych osobistości – KOBIETY",
					12: "Imiona i nazwiska zasłużonych osobistości – MĘŻCZYŹNI",
					13: "Postacie historyczne (zwykle książęta)",
					14: "Legendarne osobowości (nierozpoznane przez naukę)",
					15: "Zasłużone rodziny",
					16: "Kanonizowani lub ogłoszeni świętymi przez kościoły",
					17: "Litewskie, Bałtyckie BOGINI i stworzenia mityczne",
					18: "Litewscy, Bałtyccy BOGOWIE i stworzenia mityczne",
					19: "Litewskie imiona zwyczajowe (męskie i żeńskie)",
					21: "Nazwy części miasta Wilna, historycznych przedmieśc (przed 1945 r.)",
					22: "Nazwy wsi",
					23: "Nazwy zagrod, dworów",
					24: "Nazwy litewskich miast i osad",
					25: "Nazwy litewskich miast",
					26: "Nazwy jezior, bagien (hydronimy pochodzenia litewskiego)",
					27: "Nazwy rzek (hydronimy pochodzenia litewskiego)",
					28: "Nazwy miejscowości wzgórz, kopców i innych obiektów geograficznych",
					31: "Rośliny zielne",
					32: "Kwiaty",
					33: "Krzewy, pnącza",
					34: "Drzewa, gaje",
					35: "Ptaki",
					36: "Zwierzęta dzikie, zwierzęta domowe ",
					37: "Ryby, gady, owady",
					38: "Dary lasu",
					39: "Produkty uprawiane przez ludzi",
					41: "Elementy i środowisko rolnictwa ",
					42: "Rzemiosła wiejskie, ich wyposażenie, wyniki",
					43: "Formy oświaty, wychowania i wypoczynku",
					44: "Zawody i grupy aktywności ",
					45: "Wyznawcy nauk duchowych",
					46: "Nazwy instytucji",
					47: "Konstrukcje i budynki przemysłowe i miejskie",
					48: "Budynki i budowle w reliefie Litwy",
					51: "Przedmioty świata fizycznego i ich właściwości",
					52: "Części świata",
					53: "Pory roku i zjawiska atmosferyczne",
					54: "Charakterystyka i wyjątkowość ulic",
					55: "Elementy naturalnego reliefu",
					56: "Astronomia, kosmos i lotnictwo",
					57: "",
					58: "Produktów i sprzętu",
					59: "Uczucia",
					61: "Nazwy narodów",
					62: "Nazwy państw",
					63: "Stolice państw lub miasta zaprzyjaźnione z Wilnem",
					65: "Zmienione nazwy miejscowości Małej Litwy ",
					66: "Nazwy miejscowości pochodzenia litewskiego (bez hydronimów) w Polsce, Białorusi, Rosji, Ukrainie",
					67: "Inne nazwy nielitewskich obiektów geograficznych",
					71: "Organizacje pamięci historycznej i publiczne",
					72: "Litewskie daty historyczne",
					73: "Ziemie i regiony etnograficzne Litwy",
					74: "Obrońcy walczącej Litwy",
					75: "Osobliwość towarzyskiej, rozśpiewanej Litwy",
					76: "Rozwój i przejawy państwowości",
					77: "Dawne przejawy duchowości litewskiej",
					78: "Chrześcijańskie przejawy Litwy",
					79: "Bohaterowie i elementy baśni i legend litewskich",
					64: "Historyczne nazwy miejscowości poza Litwą, znaczące miasta WKL",
				},
			},
			maps: {
				group: {
					0: "Mapy ortofotograficzne",
					1: "Mapy Biblioteki im. Wróblewskich",
					2: "Mapy DNA Wilna",
					3: "Mapy w dużej skali",
				},
			},
		},
	},
}

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: "lt", //default
		fallbackLng: "lt",
		debug: true,
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: [
				"path",
				"cookie",
				"localStorage",
				"sessionStorage",
				"navigator",
				"querystring",
				"htmlTag",
				"subdomain",
			],

			lookupFromPathIndex: 0,
			lookupFromSubdomainIndex: 0,
		},
	})

export default i18n
