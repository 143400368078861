import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { persons, langFields } from "../../../utils/personsArcgisItems"
import PersonTimeline from "./PersonTimeline"
import PersonRelated from "./PersonRelated"
import PersonGeneral from "./PersonGeneral"
import PersonHeader from "./PersonHeader"
import EmptyPlaceholder from "../../../utils/misc/EmptyPlaceholder"
import ForceGraph from "./ForceGraph"

import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import SearchIcon from "@mui/icons-material/Search"
import IconButton from "@mui/material/IconButton"

const PersonInfo = (props) => {
	const { globalID } = useParams()
	const theme = useTheme()
	const isDownSm = useMediaQuery(theme.breakpoints.down("sm"))
	const { t, i18n } = useTranslation()

	const [biographyFeatures, setBiographyFeatures] = useState([])
	const [displayEmpty, setDisplayEmpty] = useState(true)

	useEffect(() => {
		setBiographyFeatures([])

		if (globalID) {
			props.setSelectedObject(globalID)

			persons
				.queryFeatures({
					outFields: langFields[i18n.language],
					where: `Asmenybes_ID = '{${globalID}}'`,
				})
				.then((response) => {
					setBiographyFeatures(response.features)
					setDisplayEmpty(false)

					document.getElementById("main-grid").scroll(0, 0)
				})
		} else {
			props.setSelectedObject("")
			setDisplayEmpty(true)
		}
	}, [globalID, i18n.language])

	useEffect(() => {
		return () => {
			setDisplayEmpty(true)
			setBiographyFeatures([])
		}
	}, [])

	return (
		<Grid container spacing={0} variant="main" id="main-grid">
			{isDownSm && (
				<IconButton
					color="primary"
					aria-label="close"
					size="small"
					onClick={() => {
						props.setVisible(true)
					}}
					sx={{
						mt: 1,
						mr: 1.5,
						position: "absolute",
						zIndex: 50,
						right: 0,
						left: "auto",
						backgroundColor: "#D72E30",
						color: "white",
					}}
				>
					<SearchIcon sx={{ fontSize: 25 }} />
				</IconButton>
			)}
			{displayEmpty ? (
				isDownSm ? (
					<EmptyPlaceholder display={displayEmpty} text={t("sys.selectPersonMobile")} />
				) : (
					<ForceGraph tableObjectsList={props.tableObjectsList} />
				)
			) : isDownSm ? (
				<Box
					sx={{
						pb: "45px",
						display: "grid",
						gap: 0,
						gridTemplateColumns: "repeat(1, 1fr)",
						gridTemplateRows: "auto",
						gridTemplateAreas: `
            "top"
            "left"
            "middle"
            "right"
          `,
					}}
				>
					<Box sx={{ gridArea: "top" }}>
						<PersonHeader biographyFeatures={biographyFeatures} />
						<hr
							style={{
								position: "relative",
								zIndex: 1,
								color: "#D1D1D1",
								backgroundColor: "#D1D1D1",
								height: 1,
								width: "90%",
								border: "none",
								marginTop: 32,
							}}
						/>
					</Box>
					<Box sx={{ gridArea: "left" }}>
						<PersonGeneral biographyFeatures={biographyFeatures} />
					</Box>
					<Box sx={{ gridArea: "middle" }}>
						{i18n.language === "lt" && <PersonTimeline globalID={globalID} />}
					</Box>
					<Box sx={{ gridArea: "right" }}>
						<PersonRelated globalID={globalID} />
					</Box>
				</Box>
			) : i18n.language === "lt" ? (
				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: "repeat(4, 1fr)",
						gap: 0,
						gridTemplateRows: "auto",
						gridTemplateAreas: `
              "left top top right"
            `,
					}}
				>
					<Box sx={{ gridArea: "top" }}>
						<PersonHeader biographyFeatures={biographyFeatures} />
						<hr
							style={{
								position: "relative",
								zIndex: 1,
								color: "#D1D1D1",
								backgroundColor: "#D1D1D1",
								height: 1,
								width: "90%",
								border: "none",
								marginTop: 32,
							}}
						/>
						<PersonTimeline globalID={globalID} />
					</Box>
					<Box sx={{ gridArea: "left" }}>
						<PersonGeneral biographyFeatures={biographyFeatures} />
					</Box>
					<Box sx={{ gridArea: "right" }}>
						<PersonRelated globalID={globalID} />
					</Box>
				</Box>
			) : (
				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: "repeat(2, 1fr)",
						gap: 0,
						gridTemplateRows: "auto",
						gridTemplateAreas: `
            "left right"
          `,
					}}
				>
					<Box sx={{ gridArea: "left" }}>
						<PersonGeneral biographyFeatures={biographyFeatures} />
					</Box>
					<Box sx={{ gridArea: "right" }}>
						<PersonHeader biographyFeatures={biographyFeatures} />
						<hr
							style={{
								position: "relative",
								zIndex: 1,
								color: "#D1D1D1",
								backgroundColor: "#D1D1D1",
								height: 1,
								width: "90%",
								border: "none",
								marginTop: 32,
							}}
						/>
						<PersonRelated globalID={globalID} />
					</Box>
				</Box>
			)}
		</Grid>
	)
}

export default PersonInfo
